import React, { Fragment, useEffect, useState, useContext } from 'react';
import {
  Checkbox,
  Container,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box,
  Chip,
  Typography,
  Tooltip,
  IconButton,
  Drawer,
  Divider,
  Stack,
  Button,
} from '@mui/material';
import { Shield, Visibility, Edit, Inventory, Close } from '@mui/icons-material/';
import moment from 'moment';
import SortButton from './sortButton';
import TemplateTable from './templateTable';
import BatchTagsTable from './batchEdit';
import UserProfileLink from '../../components/userProfileLink';
import { COLUMNS } from '../../constants';
import { yellow } from '@mui/material/colors';
import SelectAllCheckbox from './selectAllCheckbox';
import { formatTime } from '../../utils';
import UserContext from '../../userContext';
import FavoriteButton from './favorites';
import SearchIcon from '@mui/icons-material/Search';

const BatchTable = (props) => {
  const { items, ordering, setOrdering, hideSortArrows, hiddenColumns, fetchItems, enableSearch } = props;
  // props for search selection
  const { enableSelection, multiSelect, selectedItems, setSelectedItems, searchAction } = props;

  const [viewBatch, setViewBatch] = useState({});
  const [viewDrawerOpen, setViewDrawerOpen] = useState(false);
  const [editBatch, setEditBatch] = useState({});
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    setViewDrawerOpen(viewBatch.templates !== undefined);
  }, [viewBatch]);

  useEffect(() => {
    setEditDrawerOpen(editBatch.tags !== undefined);
  }, [editBatch]);

  const handlePreviewBatch = (e, item) => {
    e.stopPropagation();
    setViewBatch(item);
  };

  const isItemSelected = (item) => {
    if (enableSelection) {
      return selectedItems.filter((i) => i._id === item._id).length > 0;
    } else {
      return false;
    }
  };

  const onClickRow = (clickedItem) => {
    if (enableSelection) {
      if (!isItemSelected(clickedItem)) {
        // this is a selection
        if (multiSelect) {
          setSelectedItems([...selectedItems, clickedItem]);
        } else {
          setSelectedItems([clickedItem]);
        }
      } else {
        // this is a deselection
        setSelectedItems(selectedItems.filter((i) => i._id !== clickedItem._id));
      }
    }
  };

  const closePreview = () => {
    setViewBatch({});
  };

  const HideableTableCell = (props) => {
    const { column, sx, children } = props;
    return !(hiddenColumns?.includes(column) || false) && <TableCell sx={sx}>{children}</TableCell>;
  };

  return (
    <Box>
      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead>
          <TableRow>
            {enableSelection && (
              <TableCell>
                <SelectAllCheckbox {...props} />
              </TableCell>
            )}
            <HideableTableCell column={COLUMNS.DATE}>
              {!hideSortArrows && <SortButton field="created_at" ordering={ordering} setOrdering={setOrdering} />}
              Created
            </HideableTableCell>
            <TableCell>
              {!hideSortArrows && <SortButton field="name" ordering={ordering} setOrdering={setOrdering} />}
              Name
            </TableCell>
            <HideableTableCell column={COLUMNS.TEMPLATES}>Templates</HideableTableCell>
            <HideableTableCell column={COLUMNS.TAGS}>Tags</HideableTableCell>
            <HideableTableCell column={COLUMNS.ACTIONS} sx={{ textAlign: 'center' }}>
              Actions
            </HideableTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item._id} selected={isItemSelected(item)} disabled={true} onClick={() => onClickRow(item)}>
              {enableSelection && (
                <TableCell>
                  <Checkbox checked={isItemSelected(item)} />
                </TableCell>
              )}
              <HideableTableCell column={COLUMNS.DATE}>
                <Typography variant="caption">
                  {moment(item.created_at).format('MM/DD/YYYY')}
                  <br />
                  by&nbsp;
                </Typography>
                <UserProfileLink variant="caption" username={item.author} />
              </HideableTableCell>
              <TableCell>
                <Typography sx={{ fontStyle: 'bold' }}>
                  {item.name}
                  {item.private && (
                    <Tooltip title="Private">
                      <Shield sx={{ color: 'green', fontSize: 'medium', ml: 1 }} />
                    </Tooltip>
                  )}
                  {item.archived_at != null && (
                    <Tooltip title={'Archived ' + formatTime(new Date(item.archived_at))}>
                      <Inventory sx={{ color: yellow['700'], fontSize: 'medium', ml: 1 }} />
                    </Tooltip>
                  )}
                </Typography>
                <Typography variant="caption">{item.description}</Typography>
              </TableCell>
              <HideableTableCell column={COLUMNS.TEMPLATES}>
                {item.templates.map((template, index) => (
                  <Fragment key={item._id + '_t' + index}>
                    {template.name}
                    <br />
                  </Fragment>
                ))}
              </HideableTableCell>

              <HideableTableCell column={COLUMNS.TAGS} sx={{ maxWidth: '200px' }}>
                {item.tags.length > 0 ? (
                  <Box>
                    {item.tags.map((tag, index) => {
                      return (
                        <Chip
                          label={tag.name}
                          key={index}
                          variant="outlined"
                          size="small"
                          color="primary"
                          /*sx={{color: stringToColor(tag)}}*/
                        />
                      );
                    })}
                  </Box>
                ) : (
                  <Box>
                    <Typography variant="body" color="grey">
                      None
                    </Typography>
                    <br />
                  </Box>
                )}
              </HideableTableCell>

              <HideableTableCell column={COLUMNS.ACTIONS}>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100%">
                  <FavoriteButton item={item} user={user} setUser={setUser} />
                  <Tooltip title="View Batch Contents">
                    <IconButton color="primary" onClick={(e) => handlePreviewBatch(e, item)}>
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                  {!enableSelection && (
                    <Tooltip title="Edit Batch">
                      <IconButton color="primary" onClick={() => setEditBatch(item)}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  )}
                  {enableSearch && (
                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<SearchIcon />}
                      onClick={() => {
                        searchAction(item);
                      }}
                    >
                      Search
                    </Button>
                  )}
                </Box>
              </HideableTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Drawer anchor="right" open={viewDrawerOpen} sx={{ zIndex: 1399 }} onClose={closePreview}>
        {viewBatch.templates && (
          <Container>
            <Stack direction="row" justifyContent="space-between" sx={{ mt: 2, mb: 2 }}>
              <Typography variant="h5" color="primary">
                {viewBatch.name} Templates
              </Typography>
              <IconButton color="primary" onClick={closePreview}>
                <Close />
              </IconButton>
            </Stack>
            <Divider />
            <TemplateTable items={viewBatch.templates} hideSortArrows={true} hiddenColumns={[COLUMNS.ACTIONS, COLUMNS.DATE]} />
          </Container>
        )}
      </Drawer>
      <Drawer anchor="right" open={editDrawerOpen} onClose={() => setEditBatch({})}>
        {editBatch.tags && (
          <Container>
            <Typography variant="h5" color="primary" sx={{ m: 2 }}>
              Edit {editBatch.name}
            </Typography>
            <Divider />
            <BatchTagsTable
              item={editBatch}
              fetchItems={fetchItems}
              onSave={() => {
                setEditBatch(false);
              }}
            />
          </Container>
        )}
      </Drawer>
    </Box>
  );
};

export default BatchTable;
