export const ENGINES = Object.freeze({
  GOOGLE: 'GOOGLE',
  YAHOO: 'YAHOO',
  BING: 'BING',
});

export const OP_STATUS = Object.freeze({
  SUPPORTED: 'Supported',
  INCONSISTENT: 'Inconsistent',
  DEPRECATED: 'Deprecated',
});

export const OPERATORS = Object.freeze({
  OPTIONAL: {
    display: 'Optional',
    values: ['OR'],
    status: {
      [ENGINES.GOOGLE]: OP_STATUS.SUPPORTED,
      [ENGINES.YAHOO]: OP_STATUS.SUPPORTED,
      [ENGINES.BING]: OP_STATUS.SUPPORTED,
    },
    usageHelp: 'For finding results that contain either the previous or next term, without requiring both.',
  },
  WITHOUT: {
    display: 'Without',
    values: ['-'],
    status: {
      [ENGINES.GOOGLE]: OP_STATUS.SUPPORTED,
      [ENGINES.YAHOO]: OP_STATUS.SUPPORTED,
      [ENGINES.BING]: OP_STATUS.SUPPORTED,
    },
    usageHelp: 'Omits any results that contain the next term.',
  },
  EXACT: {
    display: 'Exact',
    values: ['"', '"'],
    status: {
      [ENGINES.GOOGLE]: OP_STATUS.SUPPORTED,
      [ENGINES.YAHOO]: OP_STATUS.SUPPORTED,
      [ENGINES.BING]: OP_STATUS.SUPPORTED,
    },
    usageHelp: 'Searches for the exact word or words within the quotes, in the order they are given.',
  },
  RANGE: {
    display: 'Number Range',
    values: ['..'],
    status: {
      [ENGINES.GOOGLE]: OP_STATUS.SUPPORTED,
      [ENGINES.YAHOO]: OP_STATUS.SUPPORTED,
      [ENGINES.BING]: OP_STATUS.SUPPORTED,
    },
    usageHelp:
      'The terms before and after this operator should be numbers. The search will match any numbers between (and including) those numbers.',
  },
  SITE: {
    display: 'Site',
    values: ['site:'],
    status: {
      [ENGINES.GOOGLE]: OP_STATUS.SUPPORTED,
      [ENGINES.YAHOO]: OP_STATUS.SUPPORTED,
      [ENGINES.BING]: OP_STATUS.SUPPORTED,
    },
    usageHelp:
      'The term following this operator should be a website, for example, wikipedia.org. This would filter all results to only those from wikipedia.org.',
  },
  GROUP: {
    display: 'Group',
    values: ['(', ')'],
    status: {
      [ENGINES.GOOGLE]: OP_STATUS.DEPRECATED,
      [ENGINES.YAHOO]: OP_STATUS.INCONSISTENT,
      [ENGINES.BING]: OP_STATUS.SUPPORTED,
    },
    usageHelp: 'Can be used to organize search queries, or to ensure that certain operations take place before others.',
  },
  WILDCARD: {
    display: 'Wildcard',
    values: ['*'],
    status: {
      [ENGINES.GOOGLE]: OP_STATUS.SUPPORTED,
      [ENGINES.YAHOO]: OP_STATUS.SUPPORTED,
      [ENGINES.BING]: OP_STATUS.SUPPORTED,
    },
    usageHelp:
      'Matches any word. For example, searching for the fastest * in the world could find the fastest person, animal, vehicle, etc.',
  },
});
