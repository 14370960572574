import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogActions } from '@mui/material';
import PagedTableWrapper from '../../browse/components/pagedTableWrapper';
import TemplateTable from '../../browse/components/templateTable';

/**
 * Renders the dialog that pops up and lists all templates to choose from
 * @param {boolean} open: Whether or not to render this dialog
 * @param {Function} onClose: A function that allows turning off the 'open' state
 * @param {Function} insertTemplates: A function that takes an array of selected templates and adds them to the batch
 * @returns {React.ReactNode} The add template dialog if opened
 */
const AddTemplateDialog = (props) => {
  const { open, onClose, insertTemplates } = props;
  const [selectedTemplates, setSelectedTemplates] = useState([]);

  /**
   * Called when the user wants to add the selected templates. Inserts templates, closes modal
   */
  const handleSaveSelected = () => {
    insertTemplates(selectedTemplates);
    setSelectedTemplates([]);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" height={500}>
      <DialogContent>
        <PagedTableWrapper
          title="Select Templates"
          queryBase="template/"
          defaultOrdering="-created_at"
          syncUrl={false}
          filters={['author', 'favorites']}
          useSearchBar={true}
          TableComponent={TemplateTable}
          tableProps={{
            enableSelection: true,
            multiSelect: true,
            selectedItems: selectedTemplates,
            setSelectedItems: setSelectedTemplates,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSaveSelected} variant="contained" disabled={selectedTemplates.length === 0}>
          Add Selected
        </Button>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTemplateDialog;
