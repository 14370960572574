export const PATHS = Object.freeze({
  home: '/',
  viewActivity: '/activity',
  profile: '/profile',
  search: '/search',
  searchBatch: '/search/batch',
  manageUsers: '/users',
  userGuide: '/user-guide',
  downloadUserGuide: '/download-user-guide',
  settings: '/settings',
});

export const VAR_TYPE = Object.freeze({
  VARIABLE: 'variable',
  CONSTANT: 'constant',
  OPERATION: 'operation',
});

export const ACTION_EVENTS = Object.freeze({
  // be sure to update corresponding ActionEvents in models.js
  action_create: 'created',
  action_edit: 'edited',
  action_archive: 'archived',
  action_unarchive: 'restored',
  action_searched: 'searched',
  action_config: 'config',
  action_login: 'login',
  action_logout: 'logout',
});

export const LOCALSTORAGE_IDS = Object.freeze({
  workingTemplate: 'queryContents',
  workingBatch: 'batchTemplates',
  savedBatch: 'savedBatch',
  selectedSearch: 'selectedSearch',
  selectedPlugins: 'selectedPlugins',
  searchStep: 'searchStep',
  searchResponse: 'searchResponse',
  searchResults: 'searchResults',
});

export const COLUMNS = Object.freeze({
  NAME: 'Name',
  DATE: 'Date',
  DESC: 'Desc',
  TAGS: 'Tags',
  AUTHOR: 'Author',
  ACTIONS: 'Actions',
  PREVIEW: 'Preview',
  TEMPLATES: 'Templates',
});
