import React, { Fragment } from 'react';
import { Table, TableBody, TableCell, TableRow, TableHead, Typography } from '@mui/material';
import moment from 'moment';
import UserProfileLink from '../../components/userProfileLink';
import SortButton from './sortButton';
import { Link as RouterLink } from 'react-router-dom';
import { PATHS } from '../../constants';

const TemplateLink = (props) => {
  const { name, templateID } = props;

  return (
    <Typography variant="body" className="text-link" component={RouterLink} to={PATHS.search + '?id=' + templateID} color="primary">
      {name}
    </Typography>
  );
};

const BatchLink = (props) => {
  const { name, batchID } = props;

  return (
    <Typography variant="body" className="text-link" component={RouterLink} to={PATHS.searchBatch + '?id=' + batchID} color="primary">
      {name}
    </Typography>
  );
};

const ActivityDetails = (props) => {
  const { entry, keyPrefix } = props;

  var parts = [];

  var hasTargetUser = entry.target_user && ((entry.user && entry.target_user.username !== entry.user.username) || entry.user === null);

  if (hasTargetUser) {
    parts.push(
      <>
        User <UserProfileLink username={entry.target_user.username} />
      </>
    );
  }

  if (entry.batch) {
    parts.push(
      <>
        Batch <BatchLink name={entry.batch.name} batchID={entry.batch._id} />
      </>
    );
  }

  if (entry.template) {
    parts.push(
      <>
        Template <TemplateLink name={entry.template.name} templateID={entry.template._id} />
      </>
    );
  }

  if (entry.msg) {
    parts.push(<>{entry.msg}</>);
  }

  return parts.map((part, index) => (
    <Fragment key={keyPrefix + index}>
      {index > 0 && ', '}
      {part}
    </Fragment>
  ));
};

const ActivityTable = (props) => {
  const { items, ordering, setOrdering } = props;

  return (
    <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
      <TableHead>
        <TableRow key="header">
          <TableCell width={'15%'} key="th_timestamp">
            <SortButton field="timestamp" ordering={ordering} setOrdering={setOrdering} />
            Time
          </TableCell>
          <TableCell width={'10%'} key="th_user">
            <SortButton field="user" ordering={ordering} setOrdering={setOrdering} />
            User
          </TableCell>
          <TableCell width={'10%'} key="th_event">
            <SortButton field="event" ordering={ordering} setOrdering={setOrdering} />
            Event
          </TableCell>
          <TableCell key="th_details">Details</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item) => (
          <TableRow key={item._id}>
            <TableCell>{moment(item.timestamp).format('MM/DD/YYYY h:mma')}</TableCell>
            <TableCell>
              <UserProfileLink username={item.user ? item.user.username : null} />
            </TableCell>
            <TableCell sx={{ textTransform: 'capitalize' }}>{item.event}</TableCell>
            <TableCell style={{ whiteSpace: 'pre-line' }}>
              <ActivityDetails entry={item} keyPrefix={item._id} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ActivityTable;
