import React, { useState, useContext } from 'react';
import { Container, Typography, Step, Stepper, StepLabel, Stack } from '@mui/material';
import axiosInstance, { formatAPIError } from '../axiosConfig';
import { useHistory } from 'react-router-dom';
import SelectSearch from './selectSearch';
import ConfigureSearch from './configureSearch';
import SearchResults from './searchResults';
import CenteredProgress from '../components/centeredLoader';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useStickyState } from '../utils';
import { LOCALSTORAGE_IDS } from '../constants';
import UserContext from '../userContext';
import { PATHS } from '../constants';

const steps = [
  { label: 'Selection', desc: 'Click SEARCH on a template or batch of templates' },
  { label: 'Configuration', desc: 'Enter your variable substitutions then select search engines and processing options' },
  { label: 'Processing', desc: 'Processing' },
  { label: 'Results', desc: 'Review results, flag and add notes, then export for report' },
];

const SearchRoot = () => {
  const history = useHistory();
  const [activeStep, setActiveStep] = useStickyState(0, LOCALSTORAGE_IDS.searchStep);

  const [selectedSearch, setSelectedSearch] = useStickyState([], LOCALSTORAGE_IDS.selectedSearch);
  const [selectedPlugins, setSelectedPlugins] = useStickyState([], LOCALSTORAGE_IDS.selectedPlugins);
  const [variableValues, setVariableValues] = useState({});
  const [searchResults, setSearchResults] = useStickyState([], LOCALSTORAGE_IDS.searchResults);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user, setUser } = useContext(UserContext);

  // selectedSearch is an array (compatible with multi-select tables) might be worth refactoring
  const searchSubject = selectedSearch[0] || null;

  // Send user to results when clicking 'search complete' toast
  const handleSnackClick = () => {
    history.push(PATHS.search);
  };

  /**
   * Use the current saved batch and variables to search
   */
  const searchMutation = useMutation({
    mutationFn: (searchBody) => {
      if (user.snackId !== null) {
        // Closing any previous notifications to searches being done.
        closeSnackbar(user.snackId);
      }
      setUser({ ...user, searching: true, snackId: null });
      return axiosInstance.post(`search/${searchSubject._id}/`, searchBody, { timeout: 300000 });
    },
    onIsLoading: (data, error, variables, context) => {
      // request still processing
    },
    onError: (error, variables, context) => {
      // TODO check for 409 error code and set searching to true handle polling or sub for searching to be complete. We may be able to remove this limitiation from the server.
      const snackId = enqueueSnackbar('Search failed: ' + formatAPIError(error), { variant: 'error' });
      setUser({ ...user, searchResponse: {}, searching: false, searchResultSnack: snackId });
    },
    onSuccess: (data, variables, context) => {
      if (data.status === 200) {
        data.data.batch = searchSubject;
        if (data.data.results) {
          // cause the result table to remake the rows
          data.data.reRender = true;
          const snackId = enqueueSnackbar('Search complete for batch ' + searchSubject.name + ', click to view', {
            variant: 'success',
            persist: true,
            onClick: handleSnackClick,
          });
          setUser({ ...user, searchResponse: data.data, searching: false, searchResultSnack: snackId });
        } else {
          const snackId = enqueueSnackbar(data.data.detail, { variant: 'warning' });
          setUser({ ...user, searchResponse: {}, searching: false, searchResultSnack: snackId });
        }
      } else {
        const snackId = enqueueSnackbar(data.data.detail, { variant: 'warning' });
        setUser({ ...user, searchResponse: {}, searching: false, searchResultSnack: snackId });
      }
    },
  });

  /**
   * Perform the search using the current template list and variableValues
   */
  const handleExecuteSearch = () => {
    const searchBody = { variables: variableValues, plugins: selectedPlugins, useEmphasis: true };
    searchMutation.mutate(searchBody);
  };

  // Override the current step in the process when searching or results shown
  let shownStep = activeStep;
  if (searchSubject === null) {
    shownStep = 0;
  } else if (user.searching) {
    shownStep = 2;
  } else if (user.searchResponse && Object.keys(user.searchResponse).length > 0) {
    shownStep = 3;
  }

  return (
    <Container maxWidth="false">
      <Stepper activeStep={shownStep} sx={{ pt: 0, pb: 2, pl: 6, pr: 6 }}>
        {steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={index} {...stepProps}>
              <StepLabel {...labelProps}>{shownStep === index ? step.desc : step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {shownStep === 0 && (
        <SelectSearch selectedSearch={selectedSearch} setSelectedSearch={setSelectedSearch} nextStep={() => setActiveStep(1)} />
      )}
      {shownStep === 1 && (
        <ConfigureSearch
          selectedSearch={searchSubject}
          selectedPlugins={selectedPlugins}
          setSelectedPlugins={setSelectedPlugins}
          variableValues={variableValues}
          setVariableValues={setVariableValues}
          nextStep={handleExecuteSearch}
          prevStep={() => setActiveStep(0)}
        />
      )}
      {shownStep === 2 && (
        <Stack className="center" sx={{ minHeight: '300px' }}>
          <Typography variant="h4" sx={{ pb: 2 }}>
            Submitting Search Requests and Processing Responses...
          </Typography>
          <CenteredProgress />
        </Stack>
      )}
      {shownStep === 3 && <SearchResults results={searchResults} setResults={setSearchResults} resetSearchStep={() => setActiveStep(0)} />}
    </Container>
  );
};

export default SearchRoot;
