import React from 'react';
import { Button } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const SortButton = (props) => {
  const { field, ordering, setOrdering } = props;

  const handleClickArrow = () => {
    // if already sorted by this field, invert, otherwise sort by this field
    if (field === ordering) {
      setOrdering('-' + field);
    } else {
      setOrdering(field);
    }
  };

  return (
    <Button size="small" variant="text" sx={{ p: 0, mr: 1, minWidth: '0px' }} onClick={handleClickArrow}>
      {ordering === field ? (
        <ArrowUpwardIcon />
      ) : ordering === '-' + field ? (
        <ArrowDownwardIcon />
      ) : (
        <ArrowUpwardIcon sx={{ color: 'lightgray' }} />
      )}
    </Button>
  );
};

export default SortButton;
