import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Typography,
  IconButton,
  TableContainer,
  TableBody,
  TableRow,
  TableHead,
  Table,
  TableCell,
  Grid,
  Stack,
  Tooltip,
  Alert,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import axiosInstance from '../../axiosConfig';
import UserContext from '../../userContext';
import camelCase from 'camelcase';
import CloseIcon from '@mui/icons-material/Close';
import { formatTimeLong } from '../../utils';
import RegexTextField from './RegexTextField';

const TermListEditorDialog = (props) => {
  const { open, onClose, termType } = props;
  const [rows, setRows] = useState([]);
  const [selItem, setSelItem] = useState({ display: null });
  const { user } = useContext(UserContext);
  const termTypeDisplay = termType.charAt(0).toUpperCase() + termType.slice(1);
  const [errorMsg, setErrorMsg] = useState(null);

  const [dirty, setDirty] = useState(false);
  const [readyToSave, setReadyToSave] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [display, setDisplay] = useState(null);
  const [displayError, setDisplayError] = useState(false);
  const [description, setDescription] = useState(null);
  const [author, setAuthor] = useState(null);
  const [modDate, setModDate] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);

  /**
   * Call the API to retrieve a list of variables and update the variables state
   */
  const getTerms = useCallback(() => {
    if (!termType) return;
    axiosInstance
      .get('/template-term/?term_type=' + termType)
      .then((response) => {
        setRows(response.data.results);
        setErrorMsg(null);
      })
      .catch((error) => {
        setErrorMsg({ msg: 'Failed to retrieve list', error: error });
      });
  }, [termType]);

  /**
   * useEffect to trigger on type change
   */
  useEffect(() => {
    getTerms();
  }, [open, getTerms]);

  const handleClose = (_event, reason) => {
    if (reason && reason === 'backdropClick') {
      // TODO leaving this in here in case we want to prevent closing with unsaved changes
      onClose();
    } else {
      onClose();
    }
  };

  const handleCancelClick = () => {
    updateSelItem({
      display: null,
      author: null,
      description: null,
      created_at: null,
      custom: false,
    });
    setDirty(false);
    setReadyToSave(false);
    setErrorMsg(null);
  };

  const handleDeleteClose = (doDelete) => {
    const r = itemToDelete;
    setItemToDelete(null);
    if (!doDelete) return;

    console.warn('DELETE ' + r._id);
    axiosInstance
      .delete('/template-term/' + r._id + '/')
      .then(() => {
        setRows(rows.filter((row) => row._id !== r._id));
        setErrorMsg(null);
        if (selItem._id === r._id) handleCancelClick();
      })
      .catch((error) => {
        setErrorMsg({ msg: 'Failed to delete ' + termType, error: error });
      });
  };

  const handleSaveClick = () => {
    if (isNew) {
      var data = {
        name: camelCase(display),
        display: display,
        description: description,
        term_type: termType,
        custom: true,
      };

      console.warn('handleSaveClick, new: ', data);

      axiosInstance
        .post('/template-term/', data)
        .then((resp) => {
          // clear out the fields
          handleCancelClick();
          // update the list
          getTerms();
          setErrorMsg(null);
        })
        .catch((error) => {
          setErrorMsg({ msg: 'Failed to save new ' + termType, error: error });
        });
    } else {
      console.warn('handleSaveClick, update: ');
      axiosInstance
        .patch('/template-term/' + selItem._id + '/', {
          description: description,
        })
        .then((resp) => {
          // clear out the fields
          handleCancelClick();

          // update row
          setRows(rows.map((row) => (row._id === selItem._id ? resp.data : row)));

          setErrorMsg(null);
        })
        .catch((error) => {
          setErrorMsg({ msg: 'Failed to save ' + termType, error: error });
        });
    }
  };

  const updateSelItem = (newItem) => {
    setSelItem(newItem);
    setAuthor(newItem.author);
    setDescription(newItem.description);
    setDisplay(newItem.display);
    setModDate(newItem.created_at);
    setDirty(false);
    setReadyToSave(display != null && display.length > 0);
    setIsNew(false);
    setDisplayError(false);
  };

  const handleNewClick = () => {
    updateSelItem({
      author: user.username,
      created_at: new Date(),
      display: '',
      description: '',
      custom: true,
    });
    setDirty(true);
    setReadyToSave(false);
    setIsNew(true);
    setDisplayError(true);
  };

  const handleItemClick = (state, item) => {
    updateSelItem(item);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>
        Global {termTypeDisplay} Library
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ pb: 2 }}>
          Manage the global {termType} library accessible to all users. Select item to edit or click "New" to create item. Only the
          description is editable after creation. Delete item and recreate as needed. Deleting a {termType} will not remove it from existing
          templates.
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TableContainer
              sx={{
                maxHeight: 420,
              }}
            >
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell key="display">{termTypeDisplay}</TableCell>
                    <TableCell key="description">Description</TableCell>
                    <TableCell key="author">Author</TableCell>
                    <TableCell key="action">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        onClick={(state) => {
                          handleItemClick(state, row);
                        }}
                        selected={selItem._id === row._id}
                        key={index}
                      >
                        <TableCell>{row.display}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>{row.author}</TableCell>
                        <TableCell>
                          {row.custom ? (
                            <Tooltip title="Delete" placement="left" arrow>
                              <IconButton
                                aria-label="delete"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setItemToDelete({ _id: row._id, display: row.display });
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <IconButton aria-label="delete" disabled>
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs>
            <Stack spacing={2}>
              <Button variant="contained" disabled={isNew} onClick={handleNewClick} startIcon={<AddIcon />} color="primary">
                New {termTypeDisplay}
              </Button>
              <Typography color="red">Saved Data - NO PII</Typography>
              <RegexTextField
                fullWidth
                label={isNew ? 'New ' + termTypeDisplay : termTypeDisplay}
                required
                variant="outlined"
                error={displayError}
                regex={/[^a-zA-Z0-9_ -]/g}
                inputProps={{
                  maxLength: 60,
                }}
                onChange={(e) => {
                  setDisplay(e.target.value);
                  setDirty(true);
                  setReadyToSave(e.target.validity.valid);
                  setDisplayError(!e.target.validity.valid);
                }}
                value={display || ''}
                disabled={!isNew}
                size="small"
                helperText={isNew ? '' : 'Uneditable, delete and create new ' + termType + ' to change.'}
              />
              <TextField
                fullWidth
                multiline
                label="Description"
                variant="outlined"
                rows={2}
                inputProps={{
                  maxLength: 120,
                }}
                helperText="For tooltip, leave blank unless needed"
                onChange={(e) => {
                  setDescription(e.target.value);
                  setDirty(true);
                  if (!isNew) setReadyToSave(true);
                }}
                value={description || ''}
                disabled={!(isNew || description != null) || !selItem.custom}
                size="small"
              />
              <TextField
                fullWidth
                label="Author"
                variant="outlined"
                inputProps={{ readOnly: true }}
                value={author || ''}
                disabled
                size="small"
              />
              <TextField
                fullWidth
                label="Date"
                variant="outlined"
                inputProps={{ readOnly: true }}
                value={modDate ? formatTimeLong(modDate) : ''}
                disabled
                size="small"
              />
              {errorMsg && (
                <Alert severity="error">
                  {errorMsg.msg}
                  <br />
                  {errorMsg.error.response ? errorMsg.error.response.data : errorMsg.error}
                </Alert>
              )}
              <Stack direction="row" spacing={2}>
                <Button
                  fullWidth
                  variant="contained"
                  disabled={selItem.display === null}
                  onClick={handleCancelClick}
                  startIcon={<CancelIcon />}
                >
                  Cancel
                </Button>
                <Button fullWidth variant="contained" disabled={!dirty || !readyToSave} onClick={handleSaveClick} startIcon={<SaveIcon />}>
                  Save
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <Dialog
        open={itemToDelete != null}
        onClose={() => {
          handleDeleteClose(false);
        }}
      >
        <DialogTitle>Delete {termTypeDisplay}</DialogTitle>
        <DialogContent>
          Are you sure you want to delete {termType} {itemToDelete ? itemToDelete.display : ''}?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDeleteClose(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteClose(true);
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default TermListEditorDialog;
