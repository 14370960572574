import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  Chip,
  Box,
  Tooltip,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { Shield } from '@mui/icons-material';
import { enforceCapitalization } from '../../utils';

// Empty form object
const emptyForm = { name: '', desc: '' };

/**
 * Renders a dialog allowing the user to name and describe a batch, then attempt to save it with the provided save function
 * @param {bool} open: Is the dialog open or not
 * @param {Function} onClose: A function that should set open to false
 * @param {Function} onSubmit: A function that is called when the user submits the form
 * @returns
 */
const SaveBatchDialog = (props) => {
  const { open, onClose, onSubmit, templates } = props;
  const [formValues, setFormValues] = useState(emptyForm);
  const saveDisabled = formValues.name.trim() === '';
  const [isPrivate, setIsPrivate] = useState(false);
  const [inheritPrivate, setInheritPrivate] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagField, setTagField] = useState('');

  useEffect(() => {
    if (open) {
      templates.every((template) => {
        if (template.private === true) {
          setIsPrivate(true);
          setInheritPrivate(true);
          return false;
        }
        return true;
      });
    } else {
      setIsPrivate(false);
      setInheritPrivate(false);
    }
  }, [open, templates]);

  /**
   * The user has modified one of the form fields
   * @param {InputEvent} event: The event object containing the new value
   * @param {str} field: form data field name
   */
  const handleFormChange = (event, field) => {
    setFormValues(Object.assign({}, formValues, { [field]: event.target.value }));
  };

  /**
   * User clicked save, call the supplied save function, clear and close form
   */
  const handleSaveClick = () => {
    onSubmit(enforceCapitalization(formValues.name), formValues.desc, isPrivate, [...tags, ...(tagField !== '' ? tagField.split(' ') : [])]);
    onClose();
    setFormValues(emptyForm);
  };

  const searchKeyPress = (e) => {
    if (e.key === 'Enter' && tagField !== '') {
      setTags([...tags, tagField]);
      setTagField('');
    }
  };

  const removeSearchTerm = (term) => {
    setTags(tags.filter((tag) => tag !== term));
  };

  return (
    <Dialog open={open} onClose={onClose} disableRestoreFocus>
      <DialogTitle>
        Save Batch
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter an appropriate name and detailed description, they can't be changed afterwards. This will NOT save variable
          substitutions.
          <br />
          <span style={{ color: 'red' }}>Saved Data - NO PII</span>
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Batch Name"
          fullWidth
          variant="standard"
          required={true}
          value={formValues.name}
          onChange={(event) => handleFormChange(event, 'name')}
          error={saveDisabled}
        />
        <TextField
          margin="dense"
          id="desc"
          label="Batch Description"
          fullWidth
          variant="standard"
          value={formValues.desc}
          onChange={(event) => handleFormChange(event, 'desc')}
        />
        <Box>
          <Tooltip title="Space separated, or press enter to add individually" placement="top-start">
            <TextField
              margin="dense"
              id="tags"
              label="Tags"
              fullWidth
              variant="standard"
              value={tagField}
              onChange={(event) => {
                setTagField(event.target.value);
              }}
              onKeyDown={searchKeyPress}
            />
          </Tooltip>
          {tags.map((term, index) => {
            return <Chip label={term} onDelete={() => removeSearchTerm(term)} key={index} sx={{ mr: 1 }} color="primary" />;
          })}
          {tagField
            .split(' ')
            .filter((term) => term !== '')
            .map((term, index) => {
              return <Chip label={term} key={index} sx={{ mr: 1 }} color="primary" variant="outlined" />;
            })}
        </Box>

        <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
          <Typography>Public</Typography>
          <Switch
            color="primary"
            onChange={() => {
              setIsPrivate(!isPrivate);
            }}
            checked={isPrivate}
            disabled={inheritPrivate}
          />
          <Typography>Private</Typography>
          <Shield sx={{ color: 'green', fontSize: 'medium', ml: 1 }} />
          {inheritPrivate && (
            <Typography sx={{ ml: 1 }} variant="caption">
              (forced, contains private templates)
            </Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button startIcon={<SaveIcon />} onClick={handleSaveClick} disabled={saveDisabled} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveBatchDialog;
