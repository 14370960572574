import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import Login from './login';
import LightTheme from './themes/lightTheme';
import UserContext from './userContext';
import Home from './home';
import ActivityListView from './browse/activityListView';
import UserProfile from './users/userProfile';
import RouteWithSubRoutes from './components/routeWithSubRoutes';
import { useStickyState } from './utils';
import { PATHS } from './constants';
import NavigationBar from './components/navigationBar';
import ManageUsersView from './users/manageUsersView';
import UserGuide from './userGuide/userGuide';
import SearchRoot from './search/searchRoot';
import SettingsMain from './settings/SettingsMain';
import Help from '@mui/icons-material/Help';
import { History, ManageSearch, People } from '@mui/icons-material';
import Settings from '@mui/icons-material/Settings';

// List of all paths - all are rendered in navigation bar unless hideInNav = true
// set adminOnly = true to require admin user to see path
// 'path' field is a react-router v5 route path
// supports 1 level deep of subRoute nesting
const routes = [
  {
    name: 'Home',
    path: PATHS.home,
    component: Home,
    routes: [],
    hideInNav: true,
  },
  {
    name: 'Search',
    path: PATHS.search,
    component: SearchRoot,
    routes: [
      {
        name: 'SearchBatch',
        path: PATHS.searchBatch,
        component: SearchRoot,
        hideInNav: true,
      },
    ],
    icon: ManageSearch,
  },
  {
    name: 'Users',
    path: PATHS.manageUsers,
    component: ManageUsersView,
    routes: [],
    adminOnly: true,
    icon: People,
  },
  {
    name: 'Activity',
    path: PATHS.viewActivity,
    component: ActivityListView,
    routes: [],
    icon: History,
  },
  {
    name: 'Help',
    path: PATHS.userGuide,
    component: UserGuide,
    routes: [],
    hideInNav: false,
    icon: Help,
  },
  {
    name: 'Settings',
    path: PATHS.settings,
    component: SettingsMain,
    routes: [],
    adminOnly: false,
    hideInNav: true,
    icon: Settings,
  },
  {
    name: 'Profile',
    path: PATHS.profile + '/:username',
    component: UserProfile,
    routes: [],
    hideInNav: true,
  },
];

function App() {
  const [user, setUser] = useStickyState(null, 'user');
  let navBarRoutes = null;
  let visibleRoutes = null;

  if (user) {
    // only pass navbar-visible routes to navigation bar
    navBarRoutes = routes.filter((route) => {
      return !route.hideInNav && (!route.adminOnly || user.is_admin);
    });

    // only pass pages this user is allowed to see to the router
    visibleRoutes = routes.filter((route) => {
      return !route.adminOnly || user.is_admin;
    });
  }

  // Expose context outside of react components, mainly for axios
  window.clearUserContext = () => {
    setUser(null);
  };

  return (
    <div className="App">
      <ThemeProvider theme={LightTheme}>
        <CssBaseline />
        <UserContext.Provider value={{ user, setUser }}>
          <SnackbarProvider maxSnack={3} autoHideDuration={4000} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
            <BrowserRouter>
              {user ? (
                <div>
                  <NavigationBar routes={navBarRoutes} />
                  <div className="content">
                    {visibleRoutes.map((route, index) => (
                      <RouteWithSubRoutes route={route} key={index} />
                    ))}
                  </div>
                </div>
              ) : (
                <div>
                  <Login />
                </div>
              )}
            </BrowserRouter>
          </SnackbarProvider>
        </UserContext.Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
