import React, { useState } from 'react';
import { Chip, Typography, Paper, Button, Divider, Stack, Dialog, DialogActions, DialogContent } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { VAR_TYPE } from '../../constants';
import { createVariableData } from '../queryTemplateBuilder';
import { ENGINES, OP_STATUS, OPERATORS } from './operators';
import { Help } from '@mui/icons-material';

const statusToPalette = {
  [OP_STATUS.SUPPORTED]: 'success',
  [OP_STATUS.INCONSISTENT]: 'warning',
  [OP_STATUS.DEPRECATED]: 'greyed',
};

/**
 * Renders the operation choices as several different buttons
 * @param {Array<Object>} queryContents: variables to be added, used as the rows for the table
 * @param {Function} setQueryContents: the function to modify queryContents
 * @returns {React.ReactNode} The rendered table with controls
 */
const OperationSelection = (props) => {
  const { queryContents, setQueryContents } = props;
  const [helpOperator, setHelpOperator] = useState(null);

  const appendOperatorToQuery = (display, opString, opString2 = '') => {
    const newRow = createVariableData(uuid(), opString, VAR_TYPE.OPERATION, display, '', false);
    const newQueryContents = [...queryContents];
    newQueryContents.push(newRow);

    if (opString2 !== '') {
      const newRow2 = createVariableData(uuid(), opString2, VAR_TYPE.OPERATION, display, '', false);
      newQueryContents.push(newRow2);
    }

    setQueryContents(newQueryContents);
  };

  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 1 }}>
        <Typography color="primary" variant="h6">
          Operators
        </Typography>
      </Stack>
      {Object.keys(OPERATORS).map((key) => {
        const { display, values } = OPERATORS[key];
        const op1 = values[0];
        const op2 = values[1] || '';
        const statusColor = statusToPalette[OP_STATUS.SUPPORTED];
        return (
          <Chip
            key={key}
            color={statusColor}
            onClick={() => appendOperatorToQuery(display, op1, op2)}
            label={`${display} ${op1}${op2}`}
            onDelete={() => setHelpOperator(OPERATORS[key])}
            deleteIcon={<Help />}
            sx={{ m: 0.5 }}
          />
        );
      })}
      <Dialog open={helpOperator !== null} onClose={() => setHelpOperator(null)} maxWidth="sm" fullWidth>
        <DialogContent>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
              <Typography variant="h4" color="primary">{`${helpOperator?.display || ''}`}</Typography>
              <Typography
                variant="h6"
                sx={{ px: 2, py: 0.5, borderRadius: '4px', boxShadow: '1px 1px 3px 0px grey', width: 'fit-content' }}
              >{`${helpOperator?.values[0] || ''} ${helpOperator?.values[1] || ''}`}</Typography>
            </Stack>
            <Divider />
            <Typography variant="h6" color="primary">
              Usage
            </Typography>
            <Typography variant="body">{helpOperator?.usageHelp || ''}</Typography>
            <Divider />
            <Stack direction="row" alignContent="center" spacing={2}>
              {Object.keys(ENGINES).map((engine, index) => {
                const colorKey = statusToPalette[helpOperator?.status[engine] || OP_STATUS.DEPRECATED];
                return (
                  <Stack key={index}>
                    <Typography variant="h6">{engine}</Typography>
                    <Chip label={helpOperator?.status[engine]} color={colorKey}></Chip>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setHelpOperator(null)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default OperationSelection;
