import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

function SettingsModal(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    // save user settings
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>User Settings</DialogTitle>
      <Button onClick={handleClose}>Close</Button>
    </Dialog>
  );
}

export default SettingsModal;
