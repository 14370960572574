import React, { useState, useEffect } from 'react';
import Markdown from 'react-markdown';
import {
  Typography,
  Container,
  Stack,
  List,
  Box,
  ListItem,
  ListItemText,
  ListItemButton,
  Button,
  Drawer,
  Divider,
  Paper,
} from '@mui/material';
import { ArrowBack, ArrowForward, Menu } from '@mui/icons-material';
import indexSection from './content/overview.md';
import generalWorkflow from './content/generalWorkflow.md';
import searchSelection from './content/searchSelection.md';
import searchConfiguration from './content/searchConfiguration.md';
import searchResultsPageSection from './content/searchResultsPage.md';
import userManagementSection from './content/userManagement.md';
import templateBuilder from './content/templateBuilder.md';
import batchBuilder from './content/batchBuilder.md';
import globalSettings from './content/globalSettings.md';
import activity from './content/activity.md';

import { useLocation, useHistory } from 'react-router-dom';
import { GetSearchParams } from '../utils';

const sectionMap = {
  0: { md: indexSection, title: 'Overview' },
  1: { md: generalWorkflow, title: 'General Workflow' },
  2: { md: searchSelection, title: 'Search Selection' },
  3: { md: templateBuilder, title: 'Creating New Templates' },
  4: { md: batchBuilder, title: 'Creating New Batches' },
  5: { md: searchConfiguration, title: 'Search Configuration' },
  6: { md: searchResultsPageSection, title: 'Search Results' },
  7: { md: activity, title: 'Activity Log' },
  8: { md: userManagementSection, title: 'User Management' },
  9: { md: globalSettings, title: 'Global Settings' },
};

const UserGuide = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = GetSearchParams();

  const [content, setContent] = useState('');
  const [sectionDrawerOpen, setSectionDrawerOpen] = useState(false);
  const [sectionIndex, setSectionIndex] = useState(parseInt(searchParams.section) || 0);

  // fetch markdown file contents whenever sectionIndex changes
  useEffect(() => {
    fetch(sectionMap[sectionIndex].md)
      .then((response) => response.text())
      .then((text) => {
        const imgUrlFixedText = text.replace(/\/public/g, process.env.PUBLIC_URL);
        setContent(imgUrlFixedText);
      });
  }, [sectionIndex, setContent]);

  const prevSection = sectionIndex - 1 >= 0 ? sectionMap[sectionIndex - 1].title : '';
  const nextSection = sectionIndex + 1 < Object.keys(sectionMap).length ? sectionMap[sectionIndex + 1].title : '';

  // call this whenever the page needs to be changed, changes url only
  const changeSection = (newSection) => {
    const clientParams = new URLSearchParams();
    clientParams.append('section', newSection);
    history.push({
      search: '?' + clientParams.toString(),
    });
  };

  // detects url change and adjusts state accordingly
  useEffect(() => {
    const urlSectionIndex = parseInt(searchParams.section) || 0;
    if (urlSectionIndex !== sectionIndex) {
      setSectionIndex(urlSectionIndex);
    }
  }, [history, location, sectionIndex, setSectionIndex, searchParams.section]);

  const NavButtons = (props) => {
    const { doFlex } = props;
    return (
      <Stack direction="row">
        <Button
          variant="text"
          sx={{ textTransform: 'none', minWidth: '150px', flex: doFlex ? 1 : 'inherit' }}
          onClick={() => changeSection(sectionIndex - 1)}
          disabled={prevSection === ''}
        >
          <ArrowBack />
          Previous
        </Button>
        <Divider orientation="vertical" flexItem />
        <Button
          variant="text"
          sx={{ textTransform: 'none', minWidth: '150px', flex: doFlex ? 1 : 'inherit' }}
          onClick={() => changeSection(sectionIndex + 1)}
          disabled={nextSection === ''}
        >
          Next
          <ArrowForward />
        </Button>
      </Stack>
    );
  };

  return (
    <Box>
      <Container maxWidth="xl">
        <Stack>
          <Stack direction="row" justifyContent="space-between">
            <Button variant="text" size="small" onClick={() => setSectionDrawerOpen(true)}>
              <Menu />
              Sections
            </Button>
            <Typography variant="h5" color="primary" sx={{ marginTop: '4px' }}>
              {sectionMap[sectionIndex].title}
            </Typography>
            <NavButtons sx={{ flex: 1 }} />
          </Stack>
          <Paper sx={{ mt: '32px', mb: '32px' }}>
            <Box sx={{ padding: '16px' }}>
              <Markdown className="markdown">{content}</Markdown>
            </Box>
          </Paper>
          <NavButtons sx={{ alignItems: 'center' }} doFlex={true} />
        </Stack>
      </Container>
      <Drawer anchor="left" open={sectionDrawerOpen} onClose={() => setSectionDrawerOpen(false)}>
        <Stack>
          <Typography variant="h5" color="primary" sx={{ m: 2, minWidth: '300px' }}>
            User Guide Sections
          </Typography>
          <Divider />
          <List>
            {Object.keys(sectionMap).map((sectionKey, index) => {
              return (
                <Box key={index}>
                  <ListItem>
                    <ListItemButton
                      onClick={() => {
                        changeSection(parseInt(sectionKey));
                        setSectionDrawerOpen(false);
                      }}
                    >
                      <ListItemText>{parseInt(sectionKey) + 1 + '. ' + sectionMap[sectionKey].title}</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </Box>
              );
            })}
          </List>
        </Stack>
      </Drawer>
    </Box>
  );
};

export default UserGuide;
