import React, { useEffect, useState, useContext, Fragment } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box,
  Chip,
  Typography,
  Tooltip,
  IconButton,
  Drawer,
  Container,
  Divider,
  Checkbox,
  Button,
} from '@mui/material';
import { OpenInNew, Shield, Edit, Inventory } from '@mui/icons-material/';
import SearchIcon from '@mui/icons-material/Search';
import { yellow } from '@mui/material/colors';
import UserContext from '../../userContext';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import SortButton from './sortButton';
import UserProfileLink from '../../components/userProfileLink';
import { useStickyState, openInTemplateBuilder } from '../../utils';
import QueryPreview from '../../components/queryPreview';
import EditTemplateTags from './templateEdit';
import SelectAllCheckbox from './selectAllCheckbox';
import { formatTime } from '../../utils';
import { LOCALSTORAGE_IDS, COLUMNS } from '../../constants';
import FavoriteButton from './favorites';

const TemplateTable = (props) => {
  const { items, ordering, setOrdering, hideSortArrows, hiddenColumns, fetchItems, enableSearch } = props;
  // props for search selection
  const { enableSelection, multiSelect, selectedItems, setSelectedItems, searchAction } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [, setQueryContents] = useStickyState([], LOCALSTORAGE_IDS.workingTemplate);
  const [editTemplate, setEditTemplate] = useState({});
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    setEditDrawerOpen(editTemplate.tags !== undefined);
  }, [editTemplate]);

  const handleOpenTemplate = (template) => {
    openInTemplateBuilder(template, setQueryContents, enqueueSnackbar);
  };

  const isItemSelected = (item) => {
    if (enableSelection) {
      return selectedItems.filter((i) => i._id === item._id).length > 0;
    } else {
      return false;
    }
  };

  const onClickRow = (clickedItem) => {
    if (enableSelection) {
      if (!isItemSelected(clickedItem)) {
        // this is a selection
        if (multiSelect) {
          setSelectedItems([...selectedItems, clickedItem]);
        } else {
          setSelectedItems([clickedItem]);
        }
      } else {
        // this is a deselection
        setSelectedItems(selectedItems.filter((i) => i._id !== clickedItem._id));
      }
    }
  };

  const HideableTableCell = (props) => {
    const { column, sx, children } = props;
    return !(hiddenColumns?.includes(column) || false) && <TableCell sx={sx}>{children}</TableCell>;
  };

  return (
    <Box>
      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead>
          <TableRow>
            {enableSelection && (
              <TableCell>
                <SelectAllCheckbox {...props} />
              </TableCell>
            )}
            <HideableTableCell column={COLUMNS.DATE}>
              {!hideSortArrows && <SortButton field="created_at" ordering={ordering} setOrdering={setOrdering} />}
              Created
            </HideableTableCell>
            <TableCell>
              {!hideSortArrows && <SortButton field="name" ordering={ordering} setOrdering={setOrdering} />}
              Name
            </TableCell>
            <HideableTableCell column={COLUMNS.TAGS}>Tags</HideableTableCell>
            <HideableTableCell column={COLUMNS.PREVIEW}>Preview</HideableTableCell>
            <HideableTableCell column={COLUMNS.ACTIONS} sx={{ textAlign: 'center' }}>
              Actions
            </HideableTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item._id} sx={{}} selected={isItemSelected(item)} disabled={true} onClick={() => onClickRow(item)}>
              {enableSelection && (
                <TableCell>
                  <Checkbox checked={isItemSelected(item)} />
                </TableCell>
              )}
              <HideableTableCell column={COLUMNS.DATE}>
                <Typography variant="caption">
                  {moment(item.created_at).format('MM/DD/YYYY')}
                  <br />
                  by&nbsp;
                </Typography>
                <UserProfileLink variant="caption" username={item.author} />
              </HideableTableCell>
              <TableCell>
                <Typography sx={{ fontStyle: 'bold' }}>
                  {item.name}
                  {item.private && (
                    <Tooltip title="Private">
                      <Shield sx={{ color: 'green', fontSize: 'medium', ml: 1 }} />
                    </Tooltip>
                  )}
                  {item.archived_at != null && (
                    <Tooltip title={'Archived ' + formatTime(new Date(item.archived_at))}>
                      <Inventory sx={{ color: yellow['700'], fontSize: 'medium', ml: 1 }} />
                    </Tooltip>
                  )}
                </Typography>
                <Typography variant="caption">{item.description}</Typography>
              </TableCell>
              <HideableTableCell column={COLUMNS.TAGS} sx={{ maxWidth: '200px' }}>
                {item.tags.length > 0 ? (
                  <Box>
                    {item.tags.map((tag, index) => {
                      return <Chip label={tag.name} key={index} variant="outlined" size="small" color="primary" />;
                    })}
                  </Box>
                ) : (
                  <Box>
                    <Typography variant="body" color="grey">
                      None
                    </Typography>
                    <br />
                  </Box>
                )}
              </HideableTableCell>
              <HideableTableCell column={COLUMNS.PREVIEW}>
                <div style={{ backgroundColor: '#EEEEEE', padding: 2 }}>
                  <QueryPreview template={item} />
                </div>
              </HideableTableCell>
              <HideableTableCell column={COLUMNS.ACTIONS}>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100%">
                  <FavoriteButton item={item} user={user} setUser={setUser} />
                  {!enableSelection && (
                    <Fragment>
                      <Tooltip title="New duplicate template">
                        <IconButton
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenTemplate(item);
                          }}
                        >
                          <OpenInNew />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit Template">
                        <IconButton
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditTemplate(item);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </Fragment>
                  )}
                  {enableSearch && (
                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<SearchIcon />}
                      onClick={(e) => {
                        e.stopPropagation();
                        searchAction(item);
                      }}
                    >
                      Search
                    </Button>
                  )}
                </Box>
              </HideableTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Drawer anchor="right" open={editDrawerOpen} onClose={() => setEditTemplate({})}>
        {editTemplate.tags && (
          <Container>
            <Typography variant="h5" color="primary" sx={{ m: 2 }}>
              Edit {editTemplate.name}
            </Typography>
            <Divider />
            <EditTemplateTags
              item={editTemplate}
              fetchItems={fetchItems}
              onSave={() => {
                setEditTemplate(false);
              }}
            />
          </Container>
        )}
      </Drawer>
    </Box>
  );
};

export default TemplateTable;
