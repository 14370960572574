import React, { useContext, useState } from 'react';
import axiosInstance from './axiosConfig';
import { Box, Button, TextField, Paper, Typography, Divider, Stack, Container } from '@mui/material';
import UserContext from './userContext';
import { useSnackbar } from 'notistack';
import QALogo from './images/QAlogo.png';
import PasswordChangeDialog from './components/passwordChangeDialog';
import { formatAPIError } from './axiosConfig';

const appVersion = require('../package.json').version;

const Login = () => {
  const { setUser } = useContext(UserContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [tmpUser, setTmpUser] = useState(null);

  const submitLogin = async (e) => {
    e.preventDefault();
    axiosInstance
      .post('login/', {
        username: username,
        password: password,
      })
      .then((response) => {
        console.log(response);
        const user = response.data['user'];

        if (user.change_password) {
          setTmpUser(user);
          setOpenPasswordDialog(true);
        } else {
          setUser({ ...user });
          enqueueSnackbar('Logged in as ' + user.username, { variant: 'success' });
        }
      })
      .catch((error) => {
        enqueueSnackbar('Unable to login: ' + formatAPIError(error), { variant: 'error' });
      });
  };

  const submitPasswordReset = (username, password, passwordCurrent) => {
    axiosInstance
      .post('reset-password/', {
        username: username,
        password_current: passwordCurrent,
        password_new: password,
      })
      .then(() => {
        enqueueSnackbar('Logged in as ' + tmpUser.username + ' and updated password.', { variant: 'success' });
        tmpUser.change_password = false;
        setUser({ ...tmpUser });
        setOpenPasswordDialog(false);
      })
      .catch((e) => {
        // TODO use error msg
        enqueueSnackbar('Failed to update password.', { variant: 'error' });
      });
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" margin={4} component="form" onSubmit={submitLogin}>
      <Paper sx={{ p: 2 }}>
        <Container maxWidth="sm">
          <Stack>
            <Stack direction="row" spacing={2} className="center">
              <Box>
                <img alt="logo" width="90" height="90" src={QALogo} />
              </Box>
              <Box>
                <Typography variant="h6">Query Assistant</Typography>
                <Typography variant="caption">v{appVersion}</Typography>
              </Box>
            </Stack>

            <TextField
              id="username-field"
              label="Username"
              variant="outlined"
              sx={{ m: 1 }}
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
            <TextField
              id="password-field"
              label="Password"
              variant="outlined"
              type="password"
              sx={{ m: 1 }}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />

            <Button variant="contained" sx={{ display: 'inline-block', m: 1 }} type="submit">
              Login
            </Button>

            <Divider sx={{ m: 1 }} />

            <Paper sx={{ m: 1, p: 1, backgroundColor: '#cf112a', color: 'white' }} align="center">
              <Typography variant="caption">This is a restricted government system for official judiciary business only.</Typography>
            </Paper>

            <Typography variant="caption" align="justify" sx={{ mx: 1 }}>
              <p>
                All activities on this system for any purpose, and all access attempts, may be recorded and monitored or reviewed by persons
                authorized by the federal judiciary for improper use, protection of system security, performance of maintenance, and
                appropriate management by the judiciary of its systems. By using this system or any connected system, users expressly
                consent to system monitoring and to official access to data reviewed and created by them on the system. Any evidence of
                unlawful activity, including unauthorized access attempts, may be reported to law enforcement officials.
              </p>
              <p>
                Unauthorized attempts to upload or change information on this server are strictly prohibited and may be punishable by law,
                including the Computer Fraud and Abuse Act of 1986 and the National Information Infrastructure Protection Act of 1996.
              </p>
            </Typography>
          </Stack>
        </Container>
      </Paper>

      <PasswordChangeDialog
        title="Required Password Change"
        open={openPasswordDialog}
        username={tmpUser ? tmpUser.username : ''}
        requireCurrentPassword={true}
        onClose={() => {
          setOpenPasswordDialog(false);
        }}
        onSubmit={submitPasswordReset}
      />
    </Box>
  );
};

export default Login;
