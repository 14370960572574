import React, { useState, useEffect } from 'react';
import { Typography, Stack, Paper, useTheme } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { red, grey, yellow, green } from '@mui/material/colors';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { OPERATORS } from './operators';
import EditorTerm from './editorTerm';
// import EditorPreview from './editorPreview';

const TemplateEditor = (props) => {
  const { queryContents, setQueryContents } = props;
  const [termColors, setTermColors] = useState({});
  const theme = useTheme();

  const handleRemove = (id) => {
    const term = queryContents.find((term) => term.id === id);
    const termRemoved = queryContents.filter((term) => term.id !== id);
    let pairedTarget = '';
    switch (term.varString) {
      case '"':
        pairedTarget = '"';
        break;
      case '(':
        pairedTarget = ')';
        break;
      case ')':
        pairedTarget = '(';
        break;
      default:
        break;
    }
    const pairedId = termRemoved.find((term) => term.varString === pairedTarget)?.id || '';
    setQueryContents(termRemoved.filter((term) => term.id !== pairedId));
  };

  useEffect(() => {
    let newTermColors = {};

    let inWithout = false;
    let inExact = false;
    let inRange = false;
    let inSite = false;

    queryContents.forEach((term, index) => {
      // update the colors for editor terms
      let newColor = 'white';

      if (OPERATORS.WITHOUT.display === term.varDisplay) {
        newColor = red[50];
        inWithout = true;
      } else if (inWithout) {
        newColor = red[50];
        inWithout = false;
      }

      if (OPERATORS.SITE.display === term.varDisplay) {
        newColor = yellow[50];
        inSite = true;
      } else if (inSite) {
        newColor = yellow[50];
        inSite = false;
      }

      if (OPERATORS.RANGE.display === term.varDisplay) {
        const prevTerm = queryContents[Math.max(index - 1, 0)];
        if (newTermColors[prevTerm.id] === 'white') {
          newTermColors[prevTerm.id] = green[50];
          newColor = green[50];
          inRange = true;
        }
      } else if (inRange) {
        inRange = false;
        newColor = green[50];
      }
      // calculate exact last because it overwrites other operators
      if (OPERATORS.EXACT.display === term.varDisplay) {
        newColor = grey[200];
        inExact = !inExact;
      } else if (inExact) {
        newColor = grey[200];
      }
      newTermColors[term.id] = newColor;
    });
    setTermColors(newTermColors);
  }, [queryContents]);

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    const movedTerm = queryContents.find((term) => term.id === draggableId);

    // if draggable dropped outside of droppable
    if (!destination) {
      return;
    }
    // if draggable hasn't moved
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    // if this is a deletion
    if (destination.droppableId === 'delete') {
      handleRemove(draggableId);
      return;
    }

    // apply movement
    const newQueryContents = [...queryContents];
    newQueryContents.splice(source.index, 1);
    newQueryContents.splice(destination.index, 0, movedTerm);
    setQueryContents(newQueryContents);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Paper sx={{ p: 2 }}>
        <Stack>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Typography color="primary" variant="h6" className="center">
              Editor
            </Typography>
            <Droppable droppableId={'template'} direction="horizontal">
              {(provided) => (
                <Stack
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  direction="row"
                  sx={{
                    overflowX: 'auto',
                  }}
                >
                  {queryContents.map((term, index) => (
                    <EditorTerm term={term} termColor={termColors[term.id]} key={term.id} index={index} />
                  ))}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
            <Droppable droppableId="delete">
              {(provided, snapshot) => (
                <Paper
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="center"
                  sx={{
                    background: snapshot.isDraggingOver ? red[100] : snapshot.isDragging ? theme.palette.primary.light : grey[200],
                    height: '56px !important',
                    width: '56px !important',
                    minWidth: '56px',
                    border: snapshot.isDraggingOver ? 'red solid 1px' : '',
                  }}
                >
                  <Delete
                    sx={{
                      position: 'absolute',
                      height: '48px !important',
                      width: '48px !important',
                      color: snapshot.isDraggingOver ? red[300] : grey[400],
                    }}
                  />
                  {provided.placeholder}
                </Paper>
              )}
            </Droppable>
          </Stack>
          {/* <EditorPreview queryContents={queryContents} /> */}
        </Stack>
      </Paper>
    </DragDropContext>
  );
};

export default TemplateEditor;
