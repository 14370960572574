import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { confirmable, createConfirmation } from 'react-confirm';

/**
 * Renders a dialog allowing the user to then confirm in creating a new search removing existing results.
 * Uses react-confirm to make the dialog callable.
 * @param {bool} show: Pass as true to show the confirmNewSearch dialog
 * @param {Function} proceed: Not passed in props directly, used to resolve the promise
 * @param {Function} dismiss: Not passed in props directly, closes the dialog
 * @param {Function} cancel: Not passed in props directly, used to cancel the promise
 * @returns
 */
const confirmNewSearch = (props) => {
  const { show, proceed, dismiss } = props;

  return (
    <div>
      <Dialog open={show} onClose={dismiss}>
        <DialogTitle>Start a New Search</DialogTitle>
        <DialogContent>Starting a new search will clear the current results.</DialogContent>
        <DialogActions>
          <Button onClick={() => dismiss()}>Cancel</Button>
          <Button onClick={() => proceed(true)}>Continue</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const confirmedNewSearch = createConfirmation(confirmable(confirmNewSearch));
