import { createTheme } from '@mui/material/styles';
import BaseTheme from './baseTheme';

// Only light-theme specific
const LightTheme = createTheme(
  {
    palette: {
      mode: 'light',
    },
  },
  BaseTheme
);

export default LightTheme;
