import React from 'react';
import { UsersTable, AddUserButton } from './components/usersTable';
import PagedTableWrapper from '../browse/components/pagedTableWrapper';
import { Container } from '@mui/material';

const ManageUsersView = () => {
  return (
    <Container maxWidth="xl">
      <PagedTableWrapper
        title="Users"
        queryBase="user/"
        defaultOrdering="username"
        filters={[]}
        useSearchBar={true}
        TableComponent={UsersTable}
        ExtraHeaderComponent={AddUserButton}
      />
    </Container>
  );
};

export default ManageUsersView;
