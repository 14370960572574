import React from 'react';
import { Switch, Route } from 'react-router-dom';

/**
 * Renders content based on the current URL and the components in the 'routes' object in App.js.
 * The first route with a matching path will render, and its subroutes will take priority.
 * @returns {React.ReactNode} The correct page content for the current URL path
 */
function RouteWithSubRoutes(props) {
  const { route } = props;

  return (
    <Switch>
      {route.routes.map((subRoute, index) => {
        return (
          <Route path={subRoute.path} key={index} exact>
            <subRoute.component />
          </Route>
        );
      })}
      <Route path={route.path} exact>
        <route.component />
      </Route>
    </Switch>
  );
}

export default RouteWithSubRoutes;
