import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableRow, TableHead, Box, Tooltip, IconButton, Button, TextField, Stack, Grid } from '@mui/material';
import { Delete, Add } from '@mui/icons-material/';
import axiosInstance, { formatAPIError } from '../../axiosConfig';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FormControlLabel } from '@mui/material';
import { Checkbox } from '@mui/material';
import { formatTime } from '../../utils';
import SaveIcon from '@mui/icons-material/Save';

const BatchTagsTable = (props) => {
  const { item, fetchItems, onSave } = props;
  const [origTagValues, setOrigTagValues] = useState(item.tags);
  const [tagValues, setTagValues] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isPrivate, setIsPrivate] = useState(item.private);

  var localTime = formatTime(new Date(item.archived_at));

  // Checks the value of archvied at in the item and returns true if the item is archived else false
  const checkArchive = (item) => {
    if (item.archived_at === null) {
      return false;
    }
    return true;
  };

  const [isArchived, setIsArchived] = useState(checkArchive(item));

  // Handles the deletion of a tag by filtering the current set of tags excluding the tag to delete then setting the taglist
  const handleDeleteTag = (delTag) => {
    const newTagValues = tagValues.filter((curr) => delTag._id !== curr._id);
    setTagValues(newTagValues);
  };

  const handleDeleteTagOrig = (delTag) => {
    const newTagValues = origTagValues.filter((curr) => delTag._id !== curr._id);
    setOrigTagValues(newTagValues);
  };

  // Handles the addition of a new tag by adding a new blank tag to the end of the current tag list and then setting the taglist
  // the _id is set to a unique value, this id is not neccessary outside of identifying a unique textfield, real tag ids are handled on the backend
  const handleAddTag = () => {
    const newTagValues = [...tagValues, { _id: String(Date.now()), name: '' }];
    setTagValues(newTagValues);
  };

  // Handles the change of a textfield by removing the old tag value from the tag list, getting ht eold tag value index in the array and splicing in
  // the new value at that position
  // there is probably a more effiecient way to do this
  const handleTagChange = (event, tagId) => {
    const newTagValues = tagValues.filter((curr) => curr._id !== tagId);
    const oldTagIndex = tagValues.findIndex((curr) => curr._id === tagId);
    newTagValues.splice(oldTagIndex, 0, { _id: event.target.id, name: event.target.value });
    setTagValues(newTagValues);
  };

  useEffect(() => {
    const blankIndex = tagValues.findIndex((curr) => curr.name === '');
    if (blankIndex === -1) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [tagValues]);

  // this mutation is what sends the patch request to the backend, and informs the user via snackbar items of the status
  const patchBatch = useMutation({
    mutationFn: (batch) => {
      return axiosInstance.patch('batch/', batch);
    },
    onIsLoading: (data, error, variables, context) => {
      enqueueSnackbar('Updating batch: ' + item.name, { variant: 'success' });
    },
    onError: (error, variables, context) => {
      enqueueSnackbar('Failed to update batch: ' + formatAPIError(error), { variant: 'error' });
    },
    onSuccess: (data, variables, context) => {
      enqueueSnackbar('Successfully updated batch: ' + item.name, { variant: 'success' });
      fetchItems();
      onSave && onSave();
    },
  });

  // after a user edits the fields they must hit save to generate the list of tags and send them to the backend
  const handleSubmit = (event) => {
    event.preventDefault();
    const request = {
      _id: item._id,
      tags: [...origTagValues, ...tagValues],
      private: isPrivate,
      archived_at: [item.archived_at, isArchived],
    };
    // console.log(request)
    patchBatch.mutate(request);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Tags</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {origTagValues.map((tag) => (
              <TableRow key={tag._id} sx={{}}>
                <TableCell sx={{ maxWidth: '200px' }}>
                  <TextField id={tag._id} variant="outlined" value={tag.name} disabled={true} />
                </TableCell>
                <TableCell>
                  <Box display="flex" justifyContent="center" alignItems="center" minHeight="100%">
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteTagOrig(tag)}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
            {tagValues.map((tag) => (
              <TableRow key={tag._id} sx={{}}>
                <TableCell sx={{ maxWidth: '200px' }}>
                  <TextField
                    id={tag._id}
                    variant="outlined"
                    value={tag.name}
                    onChange={(event) => handleTagChange(event, tag._id)}
                    error={tag.name.trim() === ''}
                  />
                </TableCell>
                <TableCell>
                  <Box display="flex" justifyContent="center" alignItems="center" minHeight="100%">
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteTag(tag)}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <Box display="flex" justifyContent="left" alignItems="left" minHeight="100%">
                  <Tooltip title="Add Tag">
                    <IconButton color="primary" onClick={() => handleAddTag()}>
                      <Add />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Grid container spacing={2}>
          <Grid item>
            {item.private && (
              <FormControlLabel
                value="privateSwitch"
                control={<Checkbox color="primary" />}
                label="Make Public (including templates)"
                labelPlacement="end"
                onChange={() => setIsPrivate(!isPrivate)}
                disabled={!item.private}
                checked={!isPrivate}
              />
            )}
          </Grid>
          <Grid item>
            {item.archived_at == null && (
              <FormControlLabel
                value="archiveSwitch"
                control={<Checkbox color="primary" />}
                label="Archive"
                labelPlacement="end"
                onChange={() => setIsArchived(!isArchived)}
                checked={isArchived}
              />
            )}
            {item.archived_at != null && (
              <FormControlLabel
                value="archiveSwitch"
                control={<Checkbox color="primary" />}
                label={'Restore'}
                labelPlacement="end"
                onChange={() => setIsArchived(!isArchived)}
                checked={!isArchived}
              />
            )}
          </Grid>
        </Grid>
        {item.archived_at != null && <Box>Archived on {localTime}</Box>}
        <Button type="submit" variant="contained" disabled={isDisabled} startIcon={<SaveIcon />}>
          Save
        </Button>
      </Stack>
    </form>
  );
};

export default BatchTagsTable;
