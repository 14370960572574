import React from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import axiosInstance from './axiosConfig';
import moment from 'moment';

// get the index of the first object in <array> that contains <fieldName> with a value of <fieldValue>
export const findIndexOfValue = (array, fieldName, fieldValue) => {
  return array
    .map((entry) => {
      return entry[fieldName];
    })
    .indexOf(fieldValue);
};

// get new array with moved index <from> to index <to> in <array>, optional <on> specifies how many entries starting from index <from> to move
export const moveArrayEntry = (array, from, to, on = 1) => {
  let newArray = [...array];
  return (newArray = (newArray.slice(), newArray.splice(to, 0, ...newArray.splice(from, on)), newArray));
};

// get new array with index <index> removed
export const removeIndexFromArray = (array, index) => {
  return array.slice(0, index).concat(array.slice(index + 1));
};

// compare values within 2 arrays
export const compareArrays = (a1, a2) => {
  if (a1.length === a2.length && a1.every((element, index) => element === a2[index])) {
    return true;
  } else {
    return false;
  }
};

export const enforceCapitalization= (name) =>  {
  return name.charAt(0).toUpperCase() + name.slice(1);
}

/**
 * A wrapper for react's useState that will preserve the given state upon refreshing the page.
 * Use it just like useState, but with a string key for localStorage.
 *
 * @param {*} defaultValue: Just like useState, the starting value for this state
 * @param {String} key: The string identifier for the state object in localStorage
 * @returns {Array} The same 2-value array you would get from useState normally
 */
export const useStickyState = (defaultValue, key) => {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.localStorage.getItem(key);
    let returnValue = defaultValue;
    if (stickyValue !== null) {
      try {
        returnValue = JSON.parse(stickyValue);
      } catch (e) {
        returnValue = defaultValue;
      }
    }
    return returnValue;
  });

  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
};

export const GetSearchParams = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  return Object.fromEntries(params);
};

export const ParseSearchParams = (search) => {
  const params = new URLSearchParams(search);
  return Object.fromEntries(params);
};

// deterministic color from string
export const stringToColor = (str) => {
  let hash = 0;
  str.split('').forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, '0');
  }
  return colour;
};

/**
 * Insert any number of templates into the templates state. Catches duplicates
 * @param {Array<Object>} newTemplates: An array of templates to add
 * @param {Function} enqueueSnackbar: Function to trigger notification
 * @param {Array<Object>} templates: Current list of templates
 * @param {Function} setTemplates: Setter for list of templates
 */
export const insertTemplates = (newTemplates, enqueueSnackbar, templates, setTemplates) => {
  let insertSuccess = true;
  const duplicatesRemovedTemplates = newTemplates.filter((newTemplate) => {
    const idMatches = templates.filter((template) => template._id === newTemplate._id);
    if (idMatches.length === 0) {
      return true;
    } else {
      enqueueSnackbar(newTemplate.name + ' is already in this batch.', { variant: 'error' });
      insertSuccess = false;
      return false;
    }
  });
  setTemplates([...templates, ...duplicatesRemovedTemplates]);
  return insertSuccess;
};

export const openInTemplateBuilder = (template, setQueryContents, enqueueSnackbar) => {
  let newQueryContents = [];
  template.variables.forEach((variable) => {
    newQueryContents.push({
      id: uuid(),
      varType: variable.var_type,
      varString: variable.value,
      varDisplay: variable.display,
      varDescription: variable.description,
    });
  });
  setQueryContents(newQueryContents);
  enqueueSnackbar(`Loaded ${template.name} into template builder.`, { variant: 'info' });
};

export const logout = () => {
  window.localStorage.clear();
  window.clearUserContext();
};

// check if user is logged into backend or not
export async function validateSession(enqueueSnackbar = null) {
  axiosInstance
    .get('session/')
    .then((response) => {
      if (response.data.authenticated === false) {
        logout();
        if (enqueueSnackbar) {
          enqueueSnackbar('Session expired, please log in again.', { variant: 'info' });
        }
      }
    })
    .catch((error) => {
      console.error(`Error: ${error}`);
    });
}

// returns true if the argument is a batch
export const isBatch = (target) => {
  return 'templates' in target;
};

// Formats the date into a more readable format to be displayed
export const formatTime = (date) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const newDate = date.toLocaleString('en-US', options);
  return newDate;
};

export const formatTimeLong = (timestamp) => {
  return moment(timestamp).format('MM/DD/YYYY h:mma');
};
