import React from 'react';
import { Checkbox } from '@mui/material';
import { compareArrays } from '../../utils';

const SelectAllCheckbox = (props) => {
  const { items, multiSelect, selectedItems, setSelectedItems } = props;

  const isChecked = items.length > 0 && compareArrays(selectedItems, items);
  const isIndeterminate = selectedItems.length > 0 && !isChecked;
  const isDisabled = !multiSelect;

  return (
    <Checkbox
      checked={isChecked}
      indeterminate={isIndeterminate}
      disabled={isDisabled}
      onClick={() => {
        if (isChecked || isIndeterminate) {
          setSelectedItems([]);
        } else {
          setSelectedItems(items);
        }
      }}
    />
  );
};

export default SelectAllCheckbox;
