import axios from 'axios';
import { validateSession } from './utils';
import _ from 'underscore';

let api_url = '/api/v1/';
if (process.env.REACT_APP_API_URL) {
  api_url = process.env.REACT_APP_API_URL;
} else if (process.env.NODE_ENV === 'development') {
  api_url = 'https://localhost:8000/api/v1/';
}

console.debug('api_url: %s', api_url);

const axiosInstance = axios.create({
  baseURL: api_url,
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // 403 could just mean insufficient perms, or could be invalid login, so validate
    if (error.response && error.response.status === 403) {
      validateSession();
    }
    return Promise.reject(error);
  }
);

axiosInstance.defaults.withCredentials = true;
axiosInstance.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axiosInstance.defaults.xsrfCookieName = 'csrftoken';

export default axiosInstance;

/*

error.message is from Axios, includes 

error.message: "Request failed with status code 500"
error.code: "ERR_BAD_RESPONSE"
error.response.status=500
error.response.data={type, code, detail, field_name }
error.response.statusText: "Internal Server Error"


on can't connect
error.message: "Request failed with status code 504"
error.code: "ERR_BAD_RESPONSE"
error.response.status=504
error.response.statusText="Gateway Timeout"
error.response.data="Error occurred while trying to proxy: localhost:3000/api/v1/atest/raise/"

*/

export const formatAPIError = (error) => {
  console.error(error);
  var s = error.message + '. ';
  if (error.response) {
    if (error.response.data) {
      if (error.response.data.detail) {
        // has detail
        s += String(error.response.data.detail);
      } else if (_.isString(error.response.data) && error.response.data.startsWith('<!DOCTYPE')) {
        // no detail, but data is HTML
        s += 'Unknown Exception, Contact Admin';
      } else {
        // no detail
        s += String(error.response.data);
      }
    } else if (_.isString(error.response) && error.response.startsWith('<!DOCTYPE')) {
      // error.response only, but is HTML
      s += 'Unknown Exception, Contact Admin';
    } else {
      // error.response only
      s += String(error.response);
    }
  } else {
    // error only
    s += String(error);
  }
  return s;
};
