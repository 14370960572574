import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import axiosInstance from '../../axiosConfig';
import {
  Pagination,
  Stack,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Chip,
  Box,
  Divider,
  TextField,
  Button,
  Typography,
  Tooltip,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useLocation, useHistory } from 'react-router-dom';
import CenteredProgress from '../../components/centeredLoader';
import UserContext from '../../userContext';
import FilterChip from './filterChip';
import { GetSearchParams, ParseSearchParams } from '../../utils';
import _ from 'underscore';

// Defaults for search parameters
const startingPage = 1;
const defaultPageSize = 20;
const defaultSearchTerms = [];
const allowedFilters = ['name', 'author', 'showArchived', 'showAll', 'id', 'favorites'];
const defaultFilters = { showArchived: false, showAll: true };

const PagedTableWrapper = (props) => {
  const {
    title,
    queryBase,
    defaultOrdering,
    syncUrl,
    filters,
    useSearchBar,
    TableComponent,
    ExtraHeaderComponent,
    tableProps,
    refresh = undefined,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const [searchParams, setSearchParams] = useState(syncUrl ? GetSearchParams() : undefined);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(parseInt(searchParams?.page) || startingPage);
  const [pageSize, setPageSize] = useState(parseInt(searchParams?.pageSize) || defaultPageSize);
  const [totalPages, setTotalPages] = useState(0);
  const [ordering, setOrdering] = useState(searchParams?.ordering || defaultOrdering);
  const [searchTerms, setSearchTerms] = useState(searchParams?.search?.split(',') || defaultSearchTerms);
  const [filterFields, setFilterFields] = useState(
    searchParams
      ? { ...defaultFilters, ...Object.fromEntries(Object.entries(searchParams).filter(([key, val]) => allowedFilters.includes(key))) }
      : defaultFilters
  );

  const searchInput = useRef('');
  const didInitialFetch = useRef(false);

  const { user } = useContext(UserContext);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event, value) => {
    setPageSize(event.target.value);
    setPage(startingPage);
  };

  const isFilterEnabled = useCallback(
    (filterName) => {
      return !(
        filterFields[filterName] === false ||
        filterFields[filterName] === 'false' ||
        filterFields[filterName] === '' ||
        filterFields[filterName] === undefined
      );
    },
    [filterFields]
  );

  // Call to update the listing from the backend
  const fetchItems = useCallback(async () => {
    // Translate all query params for the backend
    const serverParams = new URLSearchParams();
    serverParams.append('offset', pageSize * (page - 1));
    serverParams.append('limit', pageSize);
    if (ordering !== defaultOrdering) {
      serverParams.append('ordering', ordering);
    }
    if (useSearchBar && searchTerms.length > 0) {
      serverParams.append('search', searchTerms.join(','));
    }
    if (Object.keys(filterFields).length > 0) {
      Object.keys(filterFields).forEach((field) => serverParams.append(field, filterFields[field]));
    }
    axiosInstance
      .get(`${queryBase}?${serverParams.toString()}`)
      .then((response) => {
        const responseItems = response.data.results;
        setItems(responseItems);
        setTotalPages(Math.ceil(response.data.count / pageSize));
        setLoading(false);
      })
      .catch((error) => console.error(`Error: ${error}`));
  }, [defaultOrdering, filterFields, ordering, page, pageSize, queryBase, searchTerms, useSearchBar]);

  // Whenever search params change, update the URL path to reflect, call fetchItems if results need to be updated
  useEffect(() => {
    if (syncUrl) {
      const clientParams = new URLSearchParams();
      if (page !== startingPage) {
        clientParams.append('page', page);
      }
      if (pageSize !== defaultPageSize) {
        clientParams.append('pageSize', pageSize);
      }
      if (ordering !== defaultOrdering) {
        clientParams.append('ordering', ordering);
      }
      if (useSearchBar && searchTerms.length > 0) {
        clientParams.append('search', searchTerms.join(','));
      }
      if (Object.keys(filterFields).length > 0) {
        Object.keys(filterFields).forEach((field) => {
          // only make filter appear in client URL bar if it is enabled/not default
          if ((isFilterEnabled(field) && defaultFilters[field] !== true) || (!isFilterEnabled(field) && defaultFilters[field] === true)) {
            clientParams.append(field, filterFields[field]);
          }
        });
      }
      const newSearchString = '?' + clientParams.toString();
      if (newSearchString !== history.location.search || !didInitialFetch.current) {
        // setLoading(true);
        history.push({
          pathname: location.pathname,
          search: '?' + clientParams.toString(),
        });
        fetchItems();
        didInitialFetch.current = true;
      }
    }
  }, [
    defaultOrdering,
    fetchItems,
    isFilterEnabled,
    syncUrl,
    filterFields,
    history,
    location.pathname,
    ordering,
    page,
    pageSize,
    searchTerms,
    useSearchBar,
    queryBase,
  ]);

  useEffect(() => {
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  // if not using URL bar, call fetchItems whenever important states change
  useEffect(() => {
    if (!syncUrl) {
      fetchItems();
    }
  }, [defaultOrdering, syncUrl, fetchItems, filterFields, ordering, page, pageSize, searchTerms]);

  const searchKeyPress = (e) => {
    if (e.key === 'Enter') {
      insertSearchTerm();
    }
  };

  const removeSearchTerm = (term) => {
    setSearchTerms(searchTerms.filter((searchTerm) => searchTerm !== term));
  };

  const removeFilterField = (term) => {
    setFilterFields(_.omit(filterFields, term));
  };

  const insertSearchTerm = () => {
    if (!searchInput.current.value) return;
    var f = searchInput.current.value.trim();
    if (!f || f.length < 1) return;
    setSearchTerms([...searchTerms, f]);
    setPage(startingPage);
    searchInput.current.value = '';
  };

  const toggleArchiveFilter = (filterActive) => {
    setFilterFields({ ...filterFields, showArchived: filterActive });
  };

  const toggleAllFilter = (filterActive) => {
    setFilterFields({ ...filterFields, showAll: filterActive });
  };

  const toggleFavoritesFilter = (filterActive) => {
    setFilterFields({ ...filterFields, favorites: filterActive });
  };

  const toggleOwnedFilter = (filterActive) => {
    if (filterActive) {
      setFilterFields({ ...filterFields, author: user.username });
    } else {
      const { author, ...newFilters } = filterFields;
      setFilterFields(newFilters);
    }
  };

  const tableRef = useRef();

  useEffect(() => {
    const unlisten = history.listen((loc, action) => {
      // console.log(loc, action, loc.pathname, loc.search);
      if (syncUrl) {
        var sparams = ParseSearchParams(loc.search);

        setSearchParams(sparams);
        setFilterFields(
          sparams
            ? { ...defaultFilters, ...Object.fromEntries(Object.entries(sparams).filter(([key, val]) => allowedFilters.includes(key))) }
            : defaultFilters
        );

        setPage(parseInt(sparams?.page) || startingPage);
        setPageSize(parseInt(sparams?.pageSize) || defaultPageSize);
        setOrdering(sparams?.ordering || defaultOrdering);
        setSearchTerms(sparams?.search?.split(',') || defaultSearchTerms);
      }
    });
    return unlisten;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <div>
        <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
          <Stack direction="row" className="center">
            <Typography variant="h5" color="primary" sx={{ mr: 2 }}>
              {title}
            </Typography>
            {ExtraHeaderComponent && <ExtraHeaderComponent tableRef={tableRef} sx={{ ml: 1 }} />}
            {filters.includes('favorites') && (
              <FilterChip label="Favorites" filterMethod={toggleFavoritesFilter} isRemoval={isFilterEnabled('favorites')} />
            )}
            {filters.includes('author') && (
              <FilterChip label="Only Mine" filterMethod={toggleOwnedFilter} isRemoval={isFilterEnabled('author')} />
            )}
            {filters.includes('showArchived') && (
              <FilterChip label="Show Archived" filterMethod={toggleArchiveFilter} isRemoval={isFilterEnabled('showArchived')} />
            )}
            {filters.includes('showAll') && (
              <FilterChip label="Show All Private (Admin)" filterMethod={toggleAllFilter} isRemoval={isFilterEnabled('showAll')} />
            )}
          </Stack>

          <Box>
            {'id' in filterFields && (
              <Chip
                label="Single Item"
                onDelete={() => {
                  removeFilterField('id');
                }}
                sx={{ mr: 1 }}
                color="secondary"
              />
            )}
            {useSearchBar &&
              searchTerms.map((term, index) => {
                return <Chip label={term} onDelete={() => removeSearchTerm(term)} key={index} sx={{ mr: 1 }} color="primary" />;
              })}
            {useSearchBar && (
              <Tooltip title="Filter for any term in the title, description, tags, or author's username.">
                <TextField id="outlined-uncontrolled" label="Add Filter" size="small" inputRef={searchInput} onKeyDown={searchKeyPress} />
              </Tooltip>
            )}
            {useSearchBar && (
              <Button sx={{ minWidth: '32px' }} onClick={insertSearchTerm}>
                <FilterAltIcon />
              </Button>
            )}
          </Box>
        </Box>
        <Divider />
        {!loading ? (
          <TableComponent
            items={items}
            ordering={ordering}
            setOrdering={setOrdering}
            fetchItems={fetchItems}
            tableRef={tableRef}
            {...tableProps}
          />
        ) : (
          <Box sx={{ p: 4 }}>
            <CenteredProgress />
          </Box>
        )}
        <br />
        <Stack direction="row" className="center" spacing={2}>
          <FormControl variant="outlined" size="small" sx={{ width: '150px' }}>
            <InputLabel id="page-size-label">Rows per page:</InputLabel>
            <Select labelId="page-size-label" value={pageSize} label="Rows per page:" onChange={handlePageSizeChange}>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
          <Pagination
            className="center"
            color="primary"
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </Stack>
      </div>
    </Box>
  );
};

export default PagedTableWrapper;
