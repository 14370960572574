import React, { useEffect, useState, useContext } from 'react';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box,
  Typography,
  Tooltip,
  IconButton,
  Drawer,
  Divider,
  Button,
} from '@mui/material';
import { Edit, LockReset } from '@mui/icons-material/';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import StarsIcon from '@mui/icons-material/Stars';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import moment from 'moment';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import SortButton from '../../browse/components/sortButton';
import axiosInstance, { formatAPIError } from '../../axiosConfig';
import UserEditModal from './userEdit';
import { createuserModal } from './createUser';
import PasswordChangeDialog from '../../components/passwordChangeDialog';
import UserProfileLink from '../../components/userProfileLink';
import UserContext from '../../userContext';

export const AddUserButton = (props) => {
  const { tableRef } = props;

  const doAdd = () => {
    tableRef.current();
  };

  return (
    <Button variant="contained" onClick={doAdd} startIcon={<PersonAddIcon />}>
      Add
    </Button>
  );
};

export const UsersTable = (props) => {
  const { items, ordering, setOrdering, fetchItems, tableRef } = props;

  const [editUser, setEditUser] = useState({});
  const [editUserDrawerOpen, setEditUserDrawerOpen] = useState(false);
  const [passwordChangeUsername, setPasswordChangeUsername] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);

  function updateUserActive(username, is_active) {
    patchUser.mutate({ username: username, is_active: is_active });
  }

  const createUserMutation = useMutation({
    mutationFn: (newUser) => {
      return axiosInstance.post(`/manage-users/`, newUser);
    },
    onIsLoading: (data, error, variables, context) => {
      enqueueSnackbar('Creating new user... ', { variant: 'success' });
    },
    onError: (error, variables, context) => {
      enqueueSnackbar('Failed to add user: ' + formatAPIError(error) , { variant: 'error' });
    },
    onSuccess: (data, variables, context) => {
      console.log(data);
      enqueueSnackbar('Successfully created new user', { variant: 'success' });
      fetchItems();
    },
  });

  const closeDrawer = (setDrawer, setUser) => {
    setDrawer(false);
    setUser({});
  };

  const patchUser = useMutation({
    mutationFn: (updatedUser) => {
      return axiosInstance.patch(`/manage-users/`, updatedUser);
    },
    onIsLoading: (data, error, variables, context) => {
      enqueueSnackbar('Updating user ', { variant: 'success' });
    },
    onError: (error, variables, context) => {
      enqueueSnackbar('Failed to update user: ' + formatAPIError(error), { variant: 'error' });
    },
    onSuccess: (data, variables, context) => {
      enqueueSnackbar('Successfully updated user', { variant: 'success' });
      fetchItems();
    },
  });

  const createUser = () => {
    createuserModal({ show: true }).then(
      ({ username, email, first_name, last_name, password, is_admin, is_active }) => {
        if (username === '' || email === '' || first_name === '' || last_name === '') {
          enqueueSnackbar('Invalid format', { variant: 'error' });
          return;
        }

        const newUser = {
          username: username,
          email: email,
          first_name: first_name,
          last_name: last_name,
          password: password,
          is_admin: is_admin,
          is_active: is_active,
          change_password: true,
        };
        createUserMutation.mutate(newUser);
      },
      () => {
        console.log('cancelled');
      }
    );
  };

  tableRef.current = createUser;

  const resetUserPassword = (username, password) => {
    patchUser.mutate({ username: username, password: password });
    setPasswordChangeUsername(null);
  };

  useEffect(() => {
    if (editUser.username) {
      setEditUserDrawerOpen(true);
    }
  }, [editUser]);

  return (
    <Box>
      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <SortButton field="date_joined" ordering={ordering} setOrdering={setOrdering} />
              Date
            </TableCell>
            <TableCell>
              <SortButton field="username" ordering={ordering} setOrdering={setOrdering} />
              Username
            </TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell align="left">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.username} sx={{}}>
              <TableCell>
                <Typography sx={{ fontStyle: 'bold' }}>{moment(item.date_joined).format('MM/DD/YYYY')}</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontStyle: 'bold' }}>
                  {item.is_admin && (
                    <Tooltip title="Administrator">
                      <StarsIcon sx={{ color: 'green', fontSize: 'medium', mr: 1 }} />
                    </Tooltip>
                  )}
                  {!item.is_active && (
                    <Tooltip title="Disabled">
                      <PersonOffIcon sx={{ color: 'red', fontSize: 'medium', mr: 1 }} />
                    </Tooltip>
                  )}
                  <UserProfileLink username={item.username} />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontStyle: 'bold' }}>{item.first_name.concat(' ', item.last_name)}</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontStyle: 'bold' }}>{item.email}</Typography>
              </TableCell>
              <TableCell>
                <Box display="flex" justifyContent="left" alignItems="center" minHeight="100%">
                  <Tooltip title="Edit User">
                    <IconButton color="primary" onClick={() => setEditUser(item)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  {user.username !== item.username && (
                    <Tooltip title="Change Password">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setPasswordChangeUsername(item.username);
                        }}
                      >
                        <LockReset />
                      </IconButton>
                    </Tooltip>
                  )}
                  {item.is_active && user.username !== item.username && (
                    <Tooltip title="Disable User">
                      <IconButton
                        sx={{ color: 'red' }}
                        onClick={() => {
                          updateUserActive(item.username, false);
                        }}
                      >
                        <PersonOffIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!item.is_active && user.username !== item.username && (
                    <Tooltip title="Enable User">
                      <IconButton
                        sx={{ color: 'green' }}
                        onClick={() => {
                          updateUserActive(item.username, true);
                        }}
                      >
                        <PersonIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Drawer anchor="right" open={editUserDrawerOpen} onClose={() => closeDrawer(setEditUserDrawerOpen, setEditUser)}>
        <Container>
          <Typography variant="h5" color="primary" sx={{ m: 2 }}>
            Edit User: {editUser.username}
          </Typography>
          <Divider />
          <UserEditModal item={editUser} fetchItems={fetchItems} onClose={() => closeDrawer(setEditUserDrawerOpen, setEditUser)} />
        </Container>
      </Drawer>
      <PasswordChangeDialog
        title={'Change ' + passwordChangeUsername + "'s Password"}
        open={passwordChangeUsername != null}
        onClose={() => {
          setPasswordChangeUsername(null);
        }}
        username={passwordChangeUsername}
        requireCurrentPassword={false}
        onSubmit={resetUserPassword}
        note={'NOTE: User must change password at next logon'}
      />
    </Box>
  );
};

export default UsersTable;
