import React, { useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  Chip,
  Box,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { confirmable, createConfirmation } from 'react-confirm';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { Shield } from '@mui/icons-material';
import { enforceCapitalization } from '../../utils';

const emptyForm = { name: '', desc: '' };

/**
 * Renders a dialog allowing the user to enter a name and description for a template, then confirm saving it.
 * Uses react-confirm to make the dialog callable.
 * @param {bool} show: Pass as true to show the saveTemplate dialog
 * @param {Function} proceed: Not passed in props directly, used to resolve the promise
 * @param {Function} dismiss: Not passed in props directly, closes the dialog
 * @param {Function} cancel: Not passed in props directly, used to cancel the promise
 * @returns
 */
const SaveTemplate = (props) => {
  const { show, proceed, dismiss, cancel } = props;
  const [isPrivate, setIsPrivate] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagField, setTagField] = useState('');
  const [formValues, setFormValues] = useState(emptyForm);
  const saveDisabled = formValues.name.trim() === '';

  const handleOnClick = () => {
    return () => {
      proceed({
        name: enforceCapitalization(formValues.name),
        desc: formValues.desc,
        privateValue: isPrivate,
        tags: getSplitTags(),
      });
    };
  };

  const searchKeyPress = (e) => {
    if (e.key === 'Enter' && tagField !== '') {
      setTags(getSplitTags());
      setTagField('');
    }
  };

  const getSplitTags = () => {
    return [...tags, ...(tagField !== '' ? tagField.split(' ') : [])];
  };

  const removeSearchTerm = (term) => {
    setTags(tags.filter((tag) => tag !== term));
  };

  /**
   * The user has modified one of the form fields
   * @param {InputEvent} event: The event object containing the new value
   * @param {str} field: form data field name
   */
  const handleFormChange = (event, field) => {
    setFormValues(Object.assign({}, formValues, { [field]: event.target.value }));
  };

  return (
    <Dialog open={show} onClose={dismiss} disableRestoreFocus>
      <DialogTitle>
        Save Template
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          onClick={cancel}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter an appropriate name and detailed description, they can't be changed afterwards.
          <br />
          <span style={{ color: 'red' }}>Saved Data - NO PII</span>
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="standard"
          required={true}
          value={formValues.name}
          onChange={(event) => handleFormChange(event, 'name')}
          error={saveDisabled}
        />
        <TextField
          margin="dense"
          id="desc"
          label="Description"
          type="text"
          fullWidth
          variant="standard"
          multiline={true}
          value={formValues.desc}
          onChange={(event) => handleFormChange(event, 'desc')}
        />
        <Box>
          <TextField
            margin="dense"
            id="tags"
            label="Tags"
            fullWidth
            variant="standard"
            value={tagField}
            onChange={(event) => {
              setTagField(event.target.value);
            }}
            onKeyDown={searchKeyPress}
          />
          {tags.map((term, index) => {
            return <Chip label={term} onDelete={() => removeSearchTerm(term)} key={index} sx={{ mr: 1 }} color="primary" />;
          })}
          {tagField
            .split(' ')
            .filter((term) => term !== '')
            .map((term, index) => {
              return <Chip label={term} key={index} sx={{ mr: 1 }} color="primary" variant="outlined" />;
            })}
        </Box>

        <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
          <Typography>Public</Typography>
          <Switch
            color="primary"
            onChange={() => {
              setIsPrivate(!isPrivate);
            }}
            checked={isPrivate}
          />
          <Typography>Private</Typography>
          <Shield sx={{ color: 'green', fontSize: 'medium', ml: 1 }} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button startIcon={<SaveIcon />} onClick={handleOnClick()} variant="contained" disabled={saveDisabled}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const saveTemplate = createConfirmation(confirmable(SaveTemplate));
