import { Button, TextField, Stack, Paper, Typography, Alert } from '@mui/material';

import axiosInstance from '../../axiosConfig';
import { useEffect, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

const EmphasisListEditor = () => {
  const [emphasisText, setEmphasisText] = useState('');
  const [formatError, setFormatError] = useState('');
  const [pendingChanges, setPendingChanges] = useState(false);

  const getEmphasisList = () => {
    axiosInstance
      .get('config/emphasis_list/')
      .then((response) => {
        setEmphasisText(response.data.join('\n'));
        setPendingChanges(false);
        setFormatError('');
      })
      .catch((error) => console.error('Error: ' + error));
  };

  useEffect(() => {
    getEmphasisList();
  }, []);

  const doSave = () => {
    var work = emphasisText.trim();
    var errorList = [];
    work = work.replace('http://', '');
    work = work.replace('https://', '');
    const split_re = /\s*[,;\s]+\s*/;
    const domain_re = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
    var newList = work.split(split_re);
    for (let i = 0; i < newList.length; i++) {
      if (newList[i].startsWith('www.')) newList[i] = newList[i].substring(4);
      var n = newList[i].indexOf('/');
      if (n >= 0) newList[i] = newList[i].substring(0, n);
      var matches = newList[i].match(domain_re);
      if (matches === null || matches.length > 1) {
        errorList.push('Invalid Item: ' + newList[i]);
      }
      newList[i] = matches[0];
    }
    newList = [...new Set(newList)].sort();

    if (errorList.length) {
      setFormatError(errorList.join('\n'));
      return;
    }

    setFormatError('');
    axiosInstance
      .post('config/emphasis_list/', newList)
      .then((response) => {
        setEmphasisText(response.data.join('\n'));
        setPendingChanges(false);
        setFormatError('');
      })
      .catch((error) => console.error('Error: ' + error));
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6">Emphasis List</Typography>
      <Typography>
        Results that match any of these sites will be emphasized with an{' '}
        <PriorityHighIcon fontSize="small" className="material-icons" color="primary" /> icon. Domain/hostname only. Sites may be separated
        by new lines, spaces, commas, or semicolons. Duplicates, prefixes, ports, and paths will be automatically removed and the list will
        be sorted.
        <br />
        For example, <code>https://en.wikipedia.org:443/wiki/John_Doe</code> will be shortened to <code>en.wikipedia.org</code>.
        <br />
        NOTE: Updates will not be reflected on previous search results.
      </Typography>
      <TextField
        multiline
        fullWidth
        value={emphasisText}
        error={pendingChanges}
        onChange={(event) => {
          setEmphasisText(event.target.value);
          setPendingChanges(true);
        }}
      />
      {formatError && (
        <Alert severity="error" style={{ whiteSpace: 'pre-line' }}>
          {formatError}
        </Alert>
      )}
      <Stack direction="row" spacing={2} sx={{ mt: 2 }} justifyContent="flex-end">
        <Button variant="contained" disabled={!pendingChanges} onClick={getEmphasisList} startIcon={<CancelIcon />}>
          Cancel
        </Button>
        <Button variant="contained" disabled={!pendingChanges} onClick={doSave} startIcon={<SaveIcon />}>
          Save
        </Button>
      </Stack>
    </Paper>
  );
};

export default EmphasisListEditor;
