import React, { useContext, useEffect } from 'react';
import axiosInstance from '../axiosConfig';
import { AppBar, Container, Toolbar, Box, Menu, MenuItem, Button, IconButton, Avatar, Typography, Divider, Tooltip } from '@mui/material';
import { Link as RouterLink, Route } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import SettingsModal from './settingsModal';
import UserContext from '../userContext';
import QALogo from '../images/QAlogo.png';
import { PATHS } from '../constants';
import NavBarLink from './navBarLink';
import { validateSession, logout } from '../utils';
import { useLocation } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';

/**
 * Renders the navigation bar across the top of the screen, handles moving around the site and user options
 * @returns {React.ReactNode} Rendered navigation bar
 */
const NavigationBar = (props) => {
  const { routes } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const [profileAnchor, setProfileAnchor] = React.useState(null);
  const profileOpen = Boolean(profileAnchor);
  const path = useLocation().pathname;

  /**
   * The user has clicked on their profile avatar, and the menu needs to be anchored there
   * @param {InputEvent} event: The click event
   */
  const handleProfileClick = (event) => {
    setProfileAnchor(event.currentTarget);
  };

  /**
   * Close the user menu by removing the anchor point
   */
  const handleProfileClose = () => {
    setProfileAnchor(null);
  };

  /**
   * Clear the current user and return to the login page
   */
  const handleLogout = () => {
    axiosInstance
      .post('logout/')
      .then((response) => {
        logout();
        enqueueSnackbar('Logged out', { variant: 'info' });
      })
      .catch((error) => {
        logout();
        enqueueSnackbar("Couldn't reach server", { variant: 'error' });
      });
  };

  // When navbar is started, validate session
  useEffect(() => {
    validateSession(enqueueSnackbar);
  }, [enqueueSnackbar]);

  let iconStyle = { height: '100%', padding: '4px', borderRadius: '8px' };
  if (useLocation().pathName === PATHS.home) {
    iconStyle.background = 'red';
  }

  return (
    <AppBar position="static" color="transparent" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Container maxWidth={false}>
        <Toolbar disableGutters className="center">
          <Box sx={{ height: '80px', padding: '8px' }}>
            <RouterLink to={PATHS.home} className="text-link">
              <img alt="logo" style={iconStyle} src={QALogo} />
            </RouterLink>
          </Box>

          <Box>
            {routes
              .filter((route) => !route.hideInNav && (!route.adminOnly || user.is_admin))
              .map((route, index) => {
                return <NavBarLink route={route} variant="h5" key={index} />;
              })}
          </Box>
          <Box sx={{ flexGrow: 0, marginLeft: 'auto' }}>
            <RouterLink to={PATHS.settings}>
              <Tooltip placement="left" arrow title="Settings">
                <IconButton sx={{ mr: 1 }}>
                  <SettingsIcon color={path.includes(PATHS.settings) ? 'secondary' : 'primary'} fontSize="large" />
                </IconButton>
              </Tooltip>
            </RouterLink>
            <Button variant="text" startIcon={<Avatar alt="Avatar" src="" />} sx={{ textTransform: 'none' }} onClick={handleProfileClick}>
              {user.username}
            </Button>
            <Menu anchorEl={profileAnchor} id="account-menu" open={profileOpen} onClose={handleProfileClose} onClick={handleProfileClose}>
              <MenuItem component={RouterLink} to={`${PATHS.profile}/${user.username}`} className="text-link">
                Profile
              </MenuItem>
              <MenuItem component={RouterLink} to={`${PATHS.search}?author=${user.username}`} className="text-link">
                My Templates
              </MenuItem>
              <MenuItem component={RouterLink} to={`${PATHS.searchBatch}?author=${user.username}`} className="text-link">
                My Batches
              </MenuItem>
              <Divider />
              {/* <MenuItem onClick={() => { setSettingsOpen(true) }}>
                <Typography>Settings</Typography>
              </MenuItem> */}
              <MenuItem onClick={handleLogout}>
                <LogoutIcon sx={{ mr: 1 }} />
                <Typography variant="body" className="text-link" component={RouterLink} to={PATHS.home} color="black">
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      {routes.map((route, index) => {
        return (
          <Route path={route.path} key={index}>
            {route.routes.filter((subRoute) => subRoute.hideInNav === false).length > 0 && (
              <div>
                <Divider></Divider>
                <Toolbar className="center" variant="dense" disableGutters>
                  {route.routes.map((subRoute, index) => (
                    <NavBarLink route={subRoute} variant="h6" key={index} />
                  ))}
                </Toolbar>
              </div>
            )}
          </Route>
        );
      })}
      <SettingsModal
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
        }}
      />
    </AppBar>
  );
};

export default NavigationBar;
