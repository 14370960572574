import React, { useEffect, useState, useCallback, useContext } from 'react';
import axiosInstance, { formatAPIError } from '../axiosConfig';
import { Box, Container, Avatar, Stack, Typography, Divider, Tooltip, IconButton, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import UserContext from '../userContext';
import CenteredProgress from '../components/centeredLoader';
import TemplateTable from '../browse/components/templateTable';
import BatchTable from '../browse/components/batchTable';
import { Inventory, LockReset, Stars, PersonOff } from '@mui/icons-material/';
import PasswordChangeDialog from '../components/passwordChangeDialog';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';
import { COLUMNS } from '../constants';

/**
 * Renders the information of the user belonging to the url-specified username
 */
const UserProfile = () => {
  const { username } = useParams();
  const [profileUser, setProfileUser] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [showArchived, setShowArchived] = useState(false);

  const fetchProfile = useCallback(async () => {
    axiosInstance
      .get(`profile/${username}/?showArchived=${showArchived}`)
      .then((response) => {
        setProfileUser(response.data);
        setLoading(false);
      })
      .catch((error) => console.error(`Error: ${error}`));
  }, [username, showArchived]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile, showArchived]);

  const submitPasswordReset = (username, password, passwordCurrent) => {
    changePasswordMutation.mutate({
      username: username,
      password_current: passwordCurrent,
      password_new: password,
    });
  };

  const changePasswordMutation = useMutation({
    mutationFn: (passwordRequest) => {
      return axiosInstance.post('reset-password/', passwordRequest);
    },
    onIsLoading: (data, error, variables, context) => {},
    onError: (error, variables, context) => {
      enqueueSnackbar('Unable to change password: ' + formatAPIError(error), { variant: 'error' });
    },
    onSuccess: (data, variables, context) => {
      enqueueSnackbar('Successfully updated password.', { variant: 'success' });
      setOpenPasswordDialog(false);
    },
  });

  return (
    <Container>
      {!loading ? (
        <Box>
          <Stack direction="row">
            <Avatar alt="Avatar" sx={{ height: '150px', width: '150px' }} />
            <Divider orientation="vertical" flexItem sx={{ ml: 2, mr: 2 }} />
            <Stack>
              <Typography variant="h4">
                <Stack direction="row" spacing={2} alignItems="center">
                  {`${profileUser.first_name} ${profileUser.last_name}`}
                  {profileUser.is_admin && (
                    <Tooltip title="Administrator">
                      <Stars fontSize="large" sx={{ color: 'green' }} />
                    </Tooltip>
                  )}
                  {!profileUser.is_active && (
                    <Tooltip title="Disabled">
                      <PersonOff fontSize="large" sx={{ color: 'red', ml: 2 }} />
                    </Tooltip>
                  )}
                </Stack>
              </Typography>
              <Divider />
              <Typography variant="h5">
                Username:
                <Box sx={{ fontWeight: 'fontWeightMedium', display: 'inline', ml: 1 }}>{profileUser.username}</Box>
              </Typography>
              <Typography variant="h6">
                Member Since:
                <Box sx={{ fontWeight: 'fontWeightMedium', display: 'inline', ml: 1 }}>
                  {moment(profileUser.date_joined).format('MM/DD/YYYY')}
                </Box>
              </Typography>
              <Typography variant="h6">
                Last Login:
                <Box sx={{ fontWeight: 'fontWeightMedium', display: 'inline', ml: 1 }}>
                  {moment(profileUser.last_login).format('MM/DD/YYYY')}
                </Box>
              </Typography>
            </Stack>
            <Stack sx={{ flexGrow: 0, marginLeft: 'auto' }}>
              {user.username === username && (
                <Button startIcon={<LockReset />} variant="contained" onClick={() => setOpenPasswordDialog(true)}>
                  Change Password
                </Button>
              )}
            </Stack>
          </Stack>
          <br />
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">Most Recent Templates</Typography>
            <Tooltip title="View Achived">
              <IconButton color="primary" onClick={() => setShowArchived(!showArchived)}>
                <Inventory />
              </IconButton>
            </Tooltip>
          </Stack>
          <Divider />
          <TemplateTable
            items={profileUser.templates.slice(0, 5)}
            fetchItems={fetchProfile}
            hideSortArrows={true}
            hiddenColumns={[COLUMNS.AUTHOR]}
          />
          <br />
          <Typography variant="h5" sx={{ mb: 1 }}>
            Most Recent Batches
          </Typography>
          <Divider />
          <BatchTable
            items={profileUser.batches.slice(0, 5)}
            fetchItems={fetchProfile}
            hideSortArrows={true}
            hiddenColumns={[COLUMNS.AUTHOR]}
          />
          <br />
        </Box>
      ) : (
        <CenteredProgress />
      )}
      <PasswordChangeDialog
        title="Change Your Password"
        open={openPasswordDialog}
        onClose={() => {
          setOpenPasswordDialog(false);
        }}
        username={username}
        requireCurrentPassword={true}
        onSubmit={submitPasswordReset}
      />
    </Container>
  );
};

export default UserProfile;
