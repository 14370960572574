import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';

/**
 * This is a component for a single clickable navbar link, primarily to keep
 * some excess out of the navigationBar component
 */

const NavBarLink = (props) => {
  const { route, variant } = props;
  const visible = !(route.showInNav !== undefined && route.showInNav === false);
  const path = useLocation().pathname;

  const theme = useTheme();

  return (
    visible && (
      <Typography
        variant={variant}
        className="text-link"
        component={RouterLink}
        to={route.path}
        sx={path.includes(route.path) ? { mx: 3, color: theme.palette.secondary.main } : { mx: 3, color: theme.palette.primary.main }}
      >
        {route.icon && (
          <route.icon
            sx={{
              position: 'relative',
              top: 6,
              left: -4,
              fontSize: 32,
            }}
          />
        )}
        {route.name}
      </Typography>
    )
  );
};

export default NavBarLink;
