import React from 'react';
import { Box, Typography, Container, Button, Stack, Divider } from '@mui/material';
import { TaskAlt, ErrorOutline } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import VariableForm from './components/variableForm';
import { isBatch } from '../utils';
import PluginSelector from './components/pluginChoices';
import QueryPreview from '../components/queryPreview';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';

const ConfigureSearch = (props) => {
  const { selectedPlugins, setSelectedPlugins, selectedSearch, variableValues, setVariableValues, nextStep, prevStep } = props;

  // Either grab batch templates or place single template in array
  let templates = null;
  if (isBatch(selectedSearch)) {
    templates = selectedSearch.templates;
  } else {
    templates = [selectedSearch];
  }

  let variablesUnfinished = templates.length === 0;
  Object.keys(variableValues).forEach((varVal) => {
    if (variableValues[varVal].trim() === '') {
      variablesUnfinished = true;
    }
  });

  /**
   * Information regarding the state of the current batch being worked on
   * @returns Rendered text and icon to help the user
   */
  const BatchStatus = () => {
    let message = '';
    let icon = null;

    if (variablesUnfinished) {
      message = 'Fill out all variables';
      icon = <ErrorOutline color="warning" />;
    } else if (selectedPlugins.length === 0) {
      message = 'Select at least one search plugin';
      icon = <ErrorOutline color="warning" />;
    } else {
      message = 'Ready to search';
      icon = <TaskAlt color="success" />;
    }

    return (
      <Grid style={{ display: 'flex' }} className="center">
        <Typography sx={{ pr: 1 }}>{message}</Typography>
        {icon}
      </Grid>
    );
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Button variant="outlined" onClick={prevStep} startIcon={<ArrowBackIcon />}>
          Go Back
        </Button>
        <Stack>
          <Stack direction="row" spacing={2}>
            {BatchStatus()}
            <Button
              variant="contained"
              color="success"
              onClick={nextStep}
              disabled={variablesUnfinished || selectedPlugins.length === 0}
              startIcon={<SearchIcon />}
            >
              Start Search
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 1.5, mb: 1.5 }} />
      <Container maxWidth="xl">
        {templates && (
          <Grid container spacing={3}>
            <Grid xs={4}>
              <VariableForm templates={templates} variableValues={variableValues} setVariableValues={setVariableValues} />
            </Grid>
            <Grid xs={8}>
              <Stack spacing={1}>
                <Typography color="primary" variant="h6">
                  Search Query Preview
                </Typography>
                <Typography variant="caption">The queries sent to the active plugins</Typography>
                {templates !== null &&
                  templates.map((template, index) => {
                    return (
                      <Box key={index}>
                        <Divider />
                        <Stack direction="row" spacing={4}>
                          <Typography>Template</Typography>
                          <Stack>
                            <Typography>{template.name}</Typography>
                            <div style={{ backgroundColor: '#EEEEEE', padding: 2 }}>
                              <QueryPreview template={template} variableValues={variableValues} />
                            </div>
                          </Stack>
                        </Stack>
                      </Box>
                    );
                  })}
              </Stack>
            </Grid>
            <Grid xs={12}>
              <Divider />
            </Grid>
            <Grid xs={12}>
              <PluginSelector selectedSearch={selectedSearch} plugins={selectedPlugins} setPlugins={setSelectedPlugins} />
            </Grid>
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default ConfigureSearch;
