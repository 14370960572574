import React from 'react';
import ActivityTable from './components/activityTable';
import PagedTableWrapper from './components/pagedTableWrapper';
import UserContext from '../userContext';
import { useContext } from 'react';
import { Container } from '@mui/material';

const ActivityListView = () => {
  const { user } = useContext(UserContext);

  return (
    <Container maxWidth="xl">
      <PagedTableWrapper
        title="Activity Log"
        queryBase="activity/"
        defaultOrdering="-timestamp"
        syncUrl={true}
        filters={user.is_admin ? ['showAll'] : []}
        useSearchBar={true}
        TableComponent={ActivityTable}
      />
    </Container>
  );
};

export default ActivityListView;
