import React from 'react';
import { Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { PATHS } from '../constants';

const UserProfileLink = (props) => {
  const { username, variant = 'body' } = props;

  if (username === null) {
    return <Typography variant={variant}>System</Typography>;
  } else {
    return (
      <Typography variant={variant} className="text-link" component={RouterLink} to={PATHS.profile + '/' + username} color="primary">
        {username}
      </Typography>
    );
  }
};

export default UserProfileLink;
