import React, { useRef, useState, useContext } from 'react';
import { Button, TextField, Stack, FormControlLabel, Switch } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axiosInstance, { formatAPIError } from '../../axiosConfig';
import { useSnackbar } from 'notistack';
import UserContext from '../../userContext';

const UserEditModal = (props) => {
  const { item, fetchItems, onClose } = props;
  const email = useRef();
  const first_name = useRef();
  const last_name = useRef();
  const [isAdminChecked, setIsAdminChecked] = useState(item.is_admin);
  const [isActiveChecked, setIsActiveChecked] = useState(item.is_active);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);

  const patchUser = useMutation({
    mutationFn: (updatedUser) => {
      return axiosInstance.patch(`/manage-users/`, updatedUser);
    },
    onIsLoading: (data, error, variables, context) => {
      enqueueSnackbar('Updating user ', { variant: 'success' });
    },
    onError: (error, variables, context) => {
      enqueueSnackbar('Failed to update user: ' + formatAPIError(error), { variant: 'error' });
    },
    onSuccess: (data, variables, context) => {
      enqueueSnackbar('Successfully updated user', { variant: 'success' });
      fetchItems();
      onClose();
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    var updatedUser = {
      username: item['username'],
    };
    if (first_name.current.value !== item['first_name']) {
      updatedUser['first_name'] = first_name.current.value;
    }
    if (last_name.current.value !== item['last_name']) {
      updatedUser['last_name'] = last_name.current.value;
    }
    if (email.current.value !== item['email']) {
      updatedUser['email'] = email.current.value;
    }
    if (isAdminChecked !== item['is_admin']) {
      updatedUser['is_admin'] = isAdminChecked;
    }
    if (isActiveChecked !== item['is_active']) {
      updatedUser['is_active'] = isActiveChecked;
    }

    if (Object.keys(updatedUser).length > 1) {
      patchUser.mutate(updatedUser);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2} minWidth={'400px'}>
        <TextField label={'Username'} defaultValue={item['username']} disabled={true}></TextField>
        <TextField label={'Email'} defaultValue={item['email']} inputRef={email} required></TextField>
        <TextField label={'First Name'} defaultValue={item['first_name']} inputRef={first_name} required></TextField>
        <TextField label={'Last Name'} defaultValue={item['last_name']} inputRef={last_name} required></TextField>

        {user.username !== item.username && (
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={<Switch color="primary" />}
              label="Admin"
              labelPlacement="start"
              checked={isAdminChecked}
              onChange={() => setIsAdminChecked(!isAdminChecked)}
            ></FormControlLabel>
            <FormControlLabel
              control={<Switch color="primary" />}
              label="Enabled"
              labelPlacement="start"
              checked={isActiveChecked}
              onChange={() => setIsActiveChecked(!isActiveChecked)}
            ></FormControlLabel>
          </Stack>
        )}

        <Button type="submit" variant="contained">
          Save
        </Button>
      </Stack>
    </form>
  );
};

export default UserEditModal;
