import React, { useEffect, useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const PasswordField = (props) => {
  const {
    id,
    label,
    password,
    setPassword,
    checkReq,
    valid = undefined,
    setValid = undefined,
    match = undefined,
    notMatch = undefined,
    handleSubmit = undefined,
    ...passProps
  } = props;

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [invalidMsg, setInvalidMsg] = useState('');
  const [localValid, setLocalValid] = useState(false);

  const check = (p) => {
    if (!checkReq) {
      if (p.length < 1) {
        setInvalidMsg('missing');
        return false;
      } else {
        setInvalidMsg(' ');
        return true;
      }
    }

    if (match !== undefined) {
      if (p.length < 1) {
        setInvalidMsg('missing');
        return false;
      } else if (p === match) {
        setInvalidMsg(' ');
        return true;
      } else {
        setInvalidMsg('does not match');
        return false;
      }
    }

    if (notMatch !== undefined) {
      if (p.length > 1 && p === notMatch) {
        setInvalidMsg('must not match');
        return false;
      }
    }

    var errors = [];
    if (p.length < 8) errors.push('too short');
    if (!p.match(/[A-Z]/g)) errors.push('missing capital');
    if (!p.match(/[a-z]/g)) errors.push('missing lowercase');
    if (!p.match(/[0-9]/g)) errors.push('missing number');
    if (errors.length > 0) {
      setInvalidMsg(errors.join(', '));
      return false;
    } else {
      setInvalidMsg(' ');
      return true;
    }
  };

  const updateValid = (p) => {
    if (setValid === undefined) {
      setLocalValid(check(p));
    } else {
      setValid(check(p));
    }
  };

  const checkValid = () => {
    return setValid === undefined ? localValid : valid;
  };

  useEffect(() => {
    updateValid('');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateValid(password);
    // eslint-disable-next-line
  }, [match, password]);

  return (
    <TextField
      {...passProps}
      margin="dense"
      id={id}
      label={label}
      type={passwordVisible ? 'text' : 'password'}
      fullWidth
      variant="standard"
      required={true}
      onChange={(event) => {
        setPassword(event.target.value);
        updateValid(event.target.value);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          if (checkValid() && handleSubmit !== undefined) {
            handleSubmit();
          }
        }
      }}
      helperText={invalidMsg}
      value={password}
      error={setValid === undefined ? !localValid : !valid}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              tabIndex="-1"
              aria-label="toggle password visibility"
              onClick={() => {
                setPasswordVisible(!passwordVisible);
              }}
            >
              {passwordVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
