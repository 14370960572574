import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { Draggable } from '@hello-pangea/dnd';
import { VAR_TYPE } from '../../constants';

const EditorTerm = React.forwardRef((props, ref) => {
  const { term, termColor, index } = props;
  const theme = useTheme();

  const typeToPalette = {
    [VAR_TYPE.CONSTANT]: theme.palette.termConstant,
    [VAR_TYPE.OPERATION]: theme.palette.termOperation,
    [VAR_TYPE.VARIABLE]: theme.palette.termVariable,
  };

  return (
    <Draggable draggableId={term.id} index={index}>
      {(provided, snapshot) => (
        <Typography
          variant="h6"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            background: snapshot.isDragging ? `${typeToPalette[term.varType].main}88` : termColor || 'white',
            boxShadow: 'grey 0px 2px 4px -2px',
            transition: 'background 0.2s ease',
            '&:hover': {
              background: `${typeToPalette[term.varType].main}44`,
            },
            px: 1,
            py: 0,
            my: 1,
            mx: 0.1,
            borderRadius: '10px',
            border: `${typeToPalette[term.varType].main} solid 1px`,
            whiteSpace: 'nowrap',
            color: typeToPalette[term.varType].dark,
          }}
          className="center"
        >
          {term.varType !== VAR_TYPE.OPERATION ? term.varDisplay : term.varString}
        </Typography>
      )}
    </Draggable>
  );
});

export default EditorTerm;
