import React from 'react';
import { Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export const InternalLink = (props) => {
  const { children, path, overrideFunc } = props;

  return (
    <Typography variant="body" className="text-link" component={RouterLink} to={path} color="primary" onClick={overrideFunc}>
      {children}
    </Typography>
  );
};

export const ExternalLink = (props) => {
  const { children, path } = props;

  return (
    <Link href={path} className="text-link" color="primary" underline="none" target="_blank">
      {children}
    </Link>
  );
};
