import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Typography } from '@mui/material';
import PasswordField from './passwordField';

export const passwordRequirementsText =
  'Passwords must be at least 8 characters long and contain at least one uppercase letter, lowercase letter, and digit.';

const PasswordChangeDialog = (props) => {
  const { title, open, onClose, onSubmit, username, requireCurrentPassword, note } = props;
  const [passwordCurrent, setPasswordCurrent] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [isReady, setIsReady] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isConfirmValid, setIsConfirmValid] = useState(false);

  const handleSubmit = () => {
    if (requireCurrentPassword) {
      onSubmit(username, password, passwordCurrent);
    } else {
      onSubmit(username, password);
    }
  };

  // verify passwords match before submission and are not empty strings
  useEffect(() => {
    if (requireCurrentPassword) {
      setIsReady(password.length > 0 && isValid && isConfirmValid);
    } else {
      setIsReady(isValid && isConfirmValid);
    }
  }, [password, isValid, isConfirmValid, requireCurrentPassword]);

  // always clean out fields when closed
  useEffect(() => {
    setPasswordCurrent('');
    setPassword('');
    setPasswordConfirm('');
  }, [open]);

  return (
    <div>
      <Dialog open={open} onClose={onClose} disableRestoreFocus>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{passwordRequirementsText}</DialogContentText>
          {note && (
            <Typography className="blockquote" sx={{ my: 2 }}>
              {note}
            </Typography>
          )}
          {requireCurrentPassword && (
            <PasswordField
              id="p0"
              label="Current Password"
              password={passwordCurrent}
              setPassword={setPasswordCurrent}
              checkReq={false}
              autoFocus
              handleSubmit={handleSubmit}
              sx={{ mt: 2 }}
            />
          )}
          <PasswordField
            id="p2"
            label="New Password"
            password={password}
            setPassword={setPassword}
            checkReq={true}
            valid={isValid}
            setValid={setIsValid}
            autoFocus={!requireCurrentPassword}
            notMatch={requireCurrentPassword ? passwordCurrent : null}
            handleSubmit={handleSubmit}
          />
          <PasswordField
            id="p3"
            label="Confirm New Password"
            password={passwordConfirm}
            setPassword={setPasswordConfirm}
            checkReq={true}
            match={password}
            valid={isConfirmValid}
            setValid={setIsConfirmValid}
            handleSubmit={handleSubmit}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" disabled={!isReady} onClick={handleSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PasswordChangeDialog;
