import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import axiosInstance from '../../axiosConfig';

const FavoriteButton = (props) => {
  const { item, user, setUser } = props;
  return (
    <>
      {user.favorite_ids.includes(item._id) ? (
        <Tooltip title="Click to unfavorite">
          <IconButton
            color="error"
            onClick={(e) => {
              e.stopPropagation();
              axiosInstance.post('favorites/', { ids: user.favorite_ids.filter((id) => id !== item._id) }).then((response) => {
                setUser(response.data);
              });
            }}
          >
            <Favorite />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Click to favorite">
          <IconButton
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              axiosInstance.post('favorites/', { ids: [...user.favorite_ids, item._id] }).then((response) => {
                setUser(response.data);
              });
            }}
          >
            <FavoriteBorder />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default FavoriteButton;
