import React from 'react';
import { Divider, Box, Typography, Paper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

/**
 * Renders an information section in the dashboard
 * @param {Component} icon: The material icon to render next to the info section
 * @param {String} title: The title of the info section
 * @param {React.ReactNode} children: Expected to be text to render within the information section
 * @returns {React.ReactNode}: A formatted section of help information
 */
const DashboardBox = (props) => {
  const { icon, title, children, subtitle, xs } = props;

  return (
    <Grid xs={xs}>
      <Paper sx={{ p: 2 }}>
        <Box display="flex" justifyContent="left" alignItems="center">
          <Box sx={{ mr: 2 }}>{icon}</Box>
          <Box>
            <Typography variant="h6" color="primary">
              {title}
            </Typography>
            <Typography variant="caption" color="primary">
              {subtitle}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body">{children}</Typography>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

export default DashboardBox;
