import React, { useEffect, useState, useCallback } from 'react';
import axiosInstance from '../../axiosConfig';
import { Box, Typography, Stack, Switch, TextField, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import CenteredProgress from '../../components/centeredLoader';

/**
 * Renders the form of variable values allowing the user to enter in the substituted values
 * @param {Array<Object>} templates: The state array of all the templates in the list
 * @param {Object} variableValues: The state object holding the user's variable substitution values
 * @param {Function} setVariableValues: A function allowing modification of the variableValues state
 * @returns {React.ReactNode} The form of textfields for filling out variables
 */
const PluginSelector = (props) => {
  const { selectedSearch, plugins, setPlugins } = props;

  const [pluginChoices, setPluginChoices] = useState([]);
  const [loading, setLoading] = useState(true);

  // populates templateChoices from api
  const getAllPlugins = useCallback(async () => {
    axiosInstance
      .get('plugin/')
      .then((response) => {
        const allPlugins = response.data.results;
        setPluginChoices(allPlugins.filter((plugin) => plugin.loaded === true));
        setLoading(false);
      })
      .catch((error) => console.error(`Error: ${error}`));
  }, []);

  // populate plugins list whenever opened
  useEffect(() => {
    getAllPlugins();
  }, [getAllPlugins]);

  const togglePlugin = (pluginId) => {
    const plugin_ids = plugins.map((p) => p.id);
    if (plugin_ids.includes(pluginId)) {
      setPlugins(plugins.filter((plugin) => plugin.id !== pluginId));
    } else {
      setPlugins([
        ...plugins,
        {
          id: pluginId,
          page: 1,
          total_pages: 1,
        },
      ]);
    }
  };

  const setStartPage = (page, plugin_id) => {
    const existing_plugin = plugins.find((p) => p.id === plugin_id);
    if (existing_plugin && page > 0) {
      const updated_plugin = Object.assign({}, existing_plugin);
      updated_plugin.page = page;
      setPlugins([...plugins.filter((p) => p.id !== plugin_id), updated_plugin]);
    }
  };

  const setTotalPages = (total_pages, plugin_id) => {
    const existing_plugin = plugins.find((p) => p.id === plugin_id);
    if (existing_plugin && total_pages > 0 && total_pages <= 5) {
      const updated_plugin = Object.assign({}, existing_plugin);
      updated_plugin.total_pages = total_pages;
      setPlugins([...plugins.filter((p) => p.id !== plugin_id), updated_plugin]);
    }
  };

  return (
    <Box>
      <Stack direction="row" display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
        <Box>
          <Typography color="primary" variant="h6">
            Search Plugins
          </Typography>
          <Typography variant="caption">All queries are sent to all selected plugins</Typography>
        </Box>
      </Stack>
      {!loading ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Active</TableCell>
              <TableCell>Plugin</TableCell>
              <TableCell>Paging</TableCell>
              <TableCell>Cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pluginChoices.map((pluginChoice, index) => {
              const pluginSettings = plugins.find((p) => p.id === pluginChoice._id);
              const is_disabled = pluginSettings === undefined;
              const page = pluginSettings ? pluginSettings.page : 1;
              const total_pages = pluginSettings ? pluginSettings.total_pages : 1;
              const search_cost =
                (selectedSearch.templates?.length || 1) *
                (pluginChoice.quota_per_request + (total_pages - 1) * pluginChoice.quota_per_addtl_page);
              return (
                <TableRow key={index}>
                  <TableCell>
                    <Switch
                      checked={!is_disabled}
                      onChange={() => {
                        togglePlugin(pluginChoice._id);
                      }}
                      size="large"
                    />
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: 'bold' }}>{pluginChoice.name}</Typography>
                    <Typography variant="caption">{pluginChoice.description}</Typography>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <TextField
                        disabled={is_disabled}
                        id="page-field"
                        label="Starting Page"
                        variant="outlined"
                        size="small"
                        type="number"
                        sx={{ m: 1, width: '100px' }}
                        value={page}
                        onChange={(e) => {
                          setStartPage(e.target.value, pluginChoice._id);
                        }}
                      />
                      <TextField
                        disabled={is_disabled}
                        id="max-page-field"
                        label="Total Pages"
                        variant="outlined"
                        size="small"
                        type="number"
                        sx={{ m: 1, width: '100px' }}
                        value={total_pages}
                        onChange={(e) => {
                          setTotalPages(e.target.value, pluginChoice._id);
                        }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Stack direction="column">
                      <Typography variant="caption" textAlign="right">
                        Remaining: {pluginChoice.quota_remaining || 'N/A'}
                        <br />
                        Cost: {search_cost}
                        <br />
                        Max Results: {pluginChoice.results_per_page * total_pages}
                      </Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <CenteredProgress />
      )}
    </Box>
  );
};

export default PluginSelector;
