import { Button, Container, Stack, Typography, Accordion, AccordionSummary, AccordionDetails, Tooltip } from '@mui/material';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EmphasisListEditor from './components/emphasisList';
import TermListEditorDialog from '../templateBuilder/components/termListEditorDialog';
import { VAR_TYPE } from '../constants';
import UserContext from '../userContext';
import { useContext } from 'react';
import axiosInstance, { formatAPIError } from '../axiosConfig';
import { useSnackbar } from 'notistack';
import StarsIcon from '@mui/icons-material/Stars';
import TestPanel from './components/testPanel';

const SettingsMain = () => {
  const [editConstListOpen, setEditConstListOpen] = useState(false);
  const [editVarListOpen, setEditVarListOpen] = useState(false);
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const syncUsers = (toProvider) => {
    axiosInstance
      .post('manage-users/sync/' + (toProvider ? 'db' : 'kc') + '/')
      .then(() => {
        enqueueSnackbar('Users Synced', { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar('Failed to Sync Users:' + formatAPIError(error), { variant: 'error' });
      });
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h3" color="primary">
        Global Settings
      </Typography>
      <Stack spacing={2}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" color="primary">
              Templates and Batches
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ mb: 2 }}>
              The global variable and constant libraries are used as the basis to build search templates. Click to open the library
              managers.
            </Typography>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                onClick={() => {
                  setEditVarListOpen(true);
                }}
              >
                Global Variable Library
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setEditConstListOpen(true);
                }}
              >
                Global Constant Library
              </Button>
            </Stack>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" color="primary">
              Search and Results
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <EmphasisListEditor />
          </AccordionDetails>
        </Accordion>

        {user.is_admin && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5" color="primary">
                User Management
                <Tooltip title="Admin Only">
                  <StarsIcon sx={{ color: 'green', fontSize: 'medium', ml: 1 }} />
                </Tooltip>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Users in the application database (ADB) but not in the identity provider database (IDDB) will be added to the IDDB but will
                be disabled and require a password reset by an administrator. Users in the IDDB but not in the ADB will be added to the ADB.
                User information (names, status, email, etc.) will be synchronized. All changes can be viewed in the activity log.
              </Typography>
              <Stack spacing={2} direction={'row'} sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    syncUsers(true);
                  }}
                >
                  Sync Users to Provider
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    syncUsers(false);
                  }}
                >
                  Sync Users from Provider
                </Button>
              </Stack>
            </AccordionDetails>
          </Accordion>
        )}

        {user.is_admin && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5" color="primary">
                Test
                <Tooltip title="Admin Only">
                  <StarsIcon sx={{ color: 'green', fontSize: 'medium', ml: 1 }} />
                </Tooltip>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TestPanel />
            </AccordionDetails>
          </Accordion>
        )}
      </Stack>

      <TermListEditorDialog
        open={editConstListOpen}
        onClose={() => {
          setEditConstListOpen(false);
        }}
        termType={VAR_TYPE.CONSTANT}
      />

      <TermListEditorDialog
        open={editVarListOpen}
        onClose={() => {
          setEditVarListOpen(false);
        }}
        termType={VAR_TYPE.VARIABLE}
      />
    </Container>
  );
};

export default SettingsMain;
