import React from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import { VAR_TYPE } from '../constants';

const QueryPreview = (props) => {
  const { template, variableValues, textVariant = undefined } = props;
  const theme = useTheme();

  return (
    <Stack direction="row" spacing={1} flexWrap="wrap">
      {template.variables.map((term, index) => {
        let termText = term.display;
        let color = 'body';

        if (term.var_type === VAR_TYPE.VARIABLE) {
          termText = `[${term.display}]`;
          color = theme.palette.termVariable.dark;
          if (variableValues !== undefined) {
            // meant to have substitutions, so fill in or highlight red if missing
            const subValue = variableValues[term.value]?.trim() || '';
            if (subValue === '') {
              color = theme.palette.error.light;
            } else {
              termText = subValue;
            }
          }
        }

        if (term.var_type === VAR_TYPE.OPERATION) {
          termText = term.value;
        }

        return (
          <Typography key={index} color={color} variant={textVariant ? textVariant : 'body1'}>
            {termText}
          </Typography>
        );
      })}
    </Stack>
  );
};

export default QueryPreview;
