import { Button, Stack, Select, MenuItem, FormControl, InputLabel, Autocomplete, TextField, Divider, Typography } from '@mui/material';
import { useState } from 'react';
import axiosInstance, { formatAPIError } from '../../axiosConfig';
import { useSnackbar } from 'notistack';

const TestPanel = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [testRoot, setTestRoot] = useState('atest');
  const [testErrorType, setTestErrorType] = useState('APIException');
  const [testErrorDetail, setTestErrorDetail] = useState('');

  const doTest = (func, data, root = 'atest') => {
    axiosInstance
      .post(root + '/' + func + '/', data)
      .then((response) => {
        enqueueSnackbar(String(response.data), { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar('Test Failed: ' + formatAPIError(error), { variant: 'error' });
      });
  };

  const apiExceptions = [
    'APIException',
    'ParseError',
    'ValidationError',
    'AuthenticationFailed',
    'NotAuthenticated',
    'PermissionDenied',
    'NotFound',
    'MethodNotAllowed',
    'NotAcceptable',
  ];

  return (
    <Stack spacing={2}>
      <Stack spacing={2} direction={'row'} alignItems='center'>
        <Typography>Common Settings</Typography>
      <FormControl size="small">
          <InputLabel id="test_user_type_label">User Type</InputLabel>
          <Select
            labelId="test_user_type_label"
            value={testRoot}
            onChange={(e) => {
              setTestRoot(e.target.value);
            }}
            label="User Type"
          >
            <MenuItem value={'utest'}>Unauthorized</MenuItem>
            <MenuItem value={'atest'}>Authorized</MenuItem>
            <MenuItem value={'aatest'}>Admin Only</MenuItem>
          </Select>
        </FormControl>
        </Stack>
        <Divider />
      <Stack spacing={2} direction={'row'}>
        <Autocomplete
          size="small"
          sx={{ minWidth: 200 }}
          freeSolo
          disableClearable
          options={apiExceptions}
          renderInput={(params) => <TextField {...params} label="Exception Type" />}
          onChange={(e, v) => {
            setTestErrorType(v);
          }}
        />
        <TextField
          label="Detail"
          size="small"
          sx={{ minWidth: 300 }}
          value={testErrorDetail}
          onChange={(e) => {
            setTestErrorDetail(e.target.value);
          }}
        />
        <Button
          variant="contained"
          onClick={() => {
            doTest('raise', { type: testErrorType, detail: testErrorDetail }, testRoot);
          }}
        >
          Test Error
        </Button>
      </Stack>
    </Stack>
  );
};

export default TestPanel;
