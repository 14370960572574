import React, { useEffect, useState } from 'react';
import { Button, Typography, TextField, Box, Stack } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import { VAR_TYPE } from '../../constants';
import ClearAllIcon from '@mui/icons-material/ClearAll';

/**
 * Renders the form of variable values allowing the user to enter in the substituted values
 * @param {Array<Object>} templates: The state array of all the templates in the list
 * @param {Object} variableValues: The state object holding the user's variable substitution values
 * @param {Function} setVariableValues: A function allowing modification of the variableValues state
 * @returns {React.ReactNode} The form of textfields for filling out variables
 */

const generateVariableSet = (templates) => {
  let d = {};

  templates.forEach((template) => {
    template.variables.forEach((tvar) => {
      if (tvar.var_type === VAR_TYPE.VARIABLE) {
        d[tvar.value] = {
          display: tvar.display,
          description: tvar.description,
        };
      }
    });
  });

  return d;
};

const VariableForm = (props) => {
  const { templates, variableValues, setVariableValues } = props;

  // the array used to render the variable fields
  const [variableFields, setVariableFields] = useState(generateVariableSet(templates));

  /**
   * Clear the variable form for the currently selected template
   */
  const handleClearValues = () => {
    const newVariableValues = Object.assign({}, variableValues);
    Object.keys(newVariableValues).forEach((key) => {
      newVariableValues[key] = '';
    });
    setVariableValues(newVariableValues);
  };

  // when the template list changes, rebuild the variableValues state
  useEffect(() => {
    const newVariableValues = {};
    templates.forEach((template) => {
      template.variables
        .filter((v) => v.var_type === VAR_TYPE.VARIABLE)
        .forEach((variable) => {
          const varString = variable.value;
          if (newVariableValues[varString] === undefined) {
            newVariableValues[varString] = '';
          }
          if (newVariableValues[varString] === '') {
            // attempt to use existing variable value
            newVariableValues[varString] = variableValues[varString]?.trim() || '';
          }
        });
    });

    // prevent update loop
    if (Object.keys(variableValues).length !== Object.keys(newVariableValues).length) {
      setVariableValues(newVariableValues);
    }

    setVariableFields(generateVariableSet(templates));
  }, [templates, variableValues, setVariableValues]);

  /**
   * Modify the variableValues state with the new value the user wrote in the field
   * @param {InputEvent} event: The user input event
   */
  const handleValueChange = (event, name) => {
    const newValue = event.target.value;
    setVariableValues(Object.assign({}, variableValues, { [name]: newValue }));
  };

  return (
    <Box>
      <Stack direction="row" display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
        <Box>
          <Typography color="primary" variant="h6">
            Variable Substitutions
          </Typography>
          <Typography color="green" variant="caption">
            Temporary Data - PII Safe
          </Typography>
        </Box>
        <Button sx={{ m: 1 }} variant="outlined" onClick={handleClearValues} startIcon={<ClearAllIcon />}>
          Clear Values
        </Button>
      </Stack>
      <Grid container spacing={1}>
        {Object.keys(variableFields).length === Object.keys(variableValues).length ? (
          Object.keys(variableFields).map((k) => (
            <Grid xs={12} md={12} key={k}>
              <TextField
                label={variableFields[k].display}
                variant="outlined"
                fullWidth
                size="small"
                value={variableValues[k]}
                onChange={(event) => handleValueChange(event, k)}
                error={!variableValues[k] || variableValues[k].trim() === ''}
              />
            </Grid>
          ))
        ) : (
          <Typography>Loading</Typography>
        )}
      </Grid>
    </Box>
  );
};

export default VariableForm;
