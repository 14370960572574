import React, { useContext, useState, useEffect } from 'react';
import { Container, Typography, Stack } from '@mui/material';
import { Info, OpenInNew, HelpOutline } from '@mui/icons-material';
import UserContext from './userContext';
import Grid from '@mui/material/Unstable_Grid2';
import axiosInstance from './axiosConfig';
import DashboardBox from './components/dashboardBox';
import { InternalLink, ExternalLink } from './components/links';
import { PATHS } from './constants';

const appVersion = require('../package.json').version;

const Home = () => {
  const { user } = useContext(UserContext);
  const [apiVersion, setAPIVersion] = useState('(Loading)');

  const getAPIVersionFromAPI = () => {
    axiosInstance
      .get('version')
      .then((response) => {
        setAPIVersion('v' + response.data.version);
      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  useEffect(getAPIVersionFromAPI, []);

  return (
    <Container maxWidth="lg">
      <Typography variant="h5" sx={{ mb: 2 }}>
        Welcome {user.first_name}!
      </Typography>
      <Grid container spacing={2}>
        <DashboardBox
          xs={12}
          icon={<Info sx={{ fontSize: 80 }} color="primary" />}
          title="Query Assistant"
          subtitle={`v${appVersion} (API ${apiVersion})`}
        >
          <ul>
            <li>
              Query Assistant provides a stand-alone browser-accessible interface to queue internet searches with multiple curated queries.
            </li>
            <li>Query Assistant does not store PII or search results on its server.</li>
          </ul>
        </DashboardBox>
        <DashboardBox xs={6} title="Quick Links" icon={<OpenInNew sx={{ minHeight: 230, fontSize: 60 }} color="primary" />}>
          <ul>
            <li>
              <InternalLink path={`${PATHS.search}?author=${user.username}`}>View My Templates</InternalLink>
            </li>
            <li>
              <InternalLink path={`${PATHS.searchBatch}?author=${user.username}`}>View My Batches</InternalLink>
            </li>
            <li>
              <ExternalLink path="https://judiciarystateleg.com/">Redaction Assistance</ExternalLink>
            </li>
          </ul>
        </DashboardBox>
        <DashboardBox xs={6} title="Need Help?" icon={<HelpOutline sx={{ minHeight: 230, fontSize: 60 }} color="primary" />}>
          <Stack spacing={2}>
            <Typography variant="body">
              Use the links below to view the User Guide for Query Assistant, or to send an email to the site admins.
            </Typography>
            <ul>
              <li>
                <InternalLink path={PATHS.userGuide}>Open User Guide</InternalLink>
              </li>
              <li>
                <ExternalLink path="mailto:queryassistant@endictus.com">Contact Support</ExternalLink>
              </li>
            </ul>
          </Stack>
        </DashboardBox>
      </Grid>
    </Container>
  );
};

export default Home;
