import React, { useState } from 'react';
import axiosInstance from '../axiosConfig';
import { Button, Typography, Tooltip, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import TemplateTable from '../browse/components/templateTable';
import AddTemplateDialog from './components/addTemplateDialog';
import AddBatchDialog from './components/addBatchDialog';
import SaveBatchDialog from './components/saveBatchDialog';
import { useStickyState, findIndexOfValue } from '../utils';
import { COLUMNS, LOCALSTORAGE_IDS } from '../constants';

import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import AddBoxIcon from '@mui/icons-material/AddBox';
import RemoveIcon from '@mui/icons-material/Remove';
import SaveIcon from '@mui/icons-material/Save';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { formatAPIError } from '../axiosConfig';

/**
 * Renders the batch builder page, for creating and running batches
 * @returns {React.ReactNode} The rendered page
 */
const BatchBuilder = (props) => {
  const { onSave } = props;
  const [templates, setTemplates] = useStickyState([], LOCALSTORAGE_IDS.workingBatch);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [openBatchDialog, setOpenBatchDialog] = useState(false);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  /**
   * User has submitted a request to save the current batch with the given name and description.
   * @param {String} name: The supplied batch name
   * @param {String} desc: The supplied batch description
   * @param {Boolean} priv: The supplied privacy selection
   */
  const handleSaveBatch = (name, desc, priv, tags) => {
    let newBatch = {
      _ids: {
        templates: {
          replace: [],
        },
        tags: {
          replace: [],
        },
      },
      templates: [],
      name: name,
      private: priv,
      tags: tags,
    };
    // only include desc field if it is non-empty
    if (desc !== '') {
      newBatch['description'] = desc;
    }
    // add all template ids to the batch check private status of templates while adding them
    templates.forEach((template) => {
      newBatch._ids.templates.replace.push(template._id);
    });
    // run the mutation
    saveBatchMutation.mutate(newBatch);
  };

  /**
   * Attempt to save a new batch object to the API
   */
  const saveBatchMutation = useMutation({
    mutationFn: (newBatch) => {
      return axiosInstance.post('batch/', newBatch);
    },
    onIsLoading: (data, error, variables, context) => {
      // request still processing
      console.log(`Saving batch...`);
    },
    onError: (error, variables, context) => {
      enqueueSnackbar('Unable to Save Batch: ' + formatAPIError(error), { variant: 'error' });
    },
    onSuccess: (data, variables, context) => {
      const savedBatch = data.data;
      enqueueSnackbar('Saved New Batch: ' + savedBatch.name, { variant: 'success' });
      setTemplates([]);
      setSelectedTemplates([]);
      onSave && onSave();
    },
  });

  /**
   * The user clicked the remove button, so remove the selected template from the templates state
   */
  const handleRemoveTemplate = () => {
    let newTemplates = templates;
    selectedTemplates.forEach((tempToRemove) => {
      newTemplates = newTemplates.filter((t) => t._id !== tempToRemove._id);
    });
    setTemplates(newTemplates);
    setSelectedTemplates([]);
  };

  const insertTemplates = (newTemplates) => {
    let nonDupes = [];
    newTemplates.forEach((newTemp) => {
      const dupeIndex = findIndexOfValue(templates, '_id', newTemp._id);
      if (dupeIndex > -1) {
        enqueueSnackbar(newTemp.name + ' is already in this batch', { variant: 'warning' });
      } else {
        nonDupes.push(newTemp);
      }
    });
    setTemplates([...templates, ...nonDupes]);
    setSelectedTemplates([]);
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid xs={12}>
          <Typography className="blockquote center" variant="body">
            Create a collection of templates to run concurrently when searching.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <TemplateTable
            hideSortArrows={true}
            items={templates}
            enableSelection={true}
            multiSelect={true}
            selectedItems={selectedTemplates}
            setSelectedItems={setSelectedTemplates}
            hiddenColumns={[COLUMNS.ACTIONS]}
          />
        </Grid>

        <Grid xs={9} display="flex" justifyContent="left" sx={{ pt: 2 }}>
          <Tooltip title="Add a template" enterDelay={500}>
            <Button variant="contained" onClick={() => setOpenTemplateDialog(true)} startIcon={<AddBoxIcon />}>
              Add Template
            </Button>
          </Tooltip>
          <Tooltip title="Import an existing batch" enterDelay={500}>
            <Button sx={{ mx: 2 }} variant="contained" onClick={() => setOpenBatchDialog(true)} startIcon={<LibraryAddIcon />}>
              Add Templates From Batch
            </Button>
          </Tooltip>
          <Tooltip title="Remove selected templates" enterDelay={500}>
            <span>
              <Button
                sx={{ mr: 2 }}
                color="error"
                variant="contained"
                onClick={handleRemoveTemplate}
                disabled={selectedTemplates.length === 0}
                startIcon={<RemoveIcon />}
              >
                Remove Checked
              </Button>
            </span>
          </Tooltip>
          <Tooltip title="Remove all templates" enterDelay={500}>
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                setTemplates([]);
                setSelectedTemplates([]);
              }}
              startIcon={<ClearAllIcon />}
            >
              Clear
            </Button>
          </Tooltip>
        </Grid>
        <Grid xs={3} display="flex" justifyContent="right" sx={{ pt: 2 }}>
          <Button variant="contained" onClick={() => setOpenSaveDialog(true)} disabled={templates.length === 0} startIcon={<SaveIcon />}>
            Save as New Batch
          </Button>
        </Grid>
      </Grid>
      <SaveBatchDialog
        open={openSaveDialog}
        onClose={() => {
          setOpenSaveDialog(false);
        }}
        onSubmit={handleSaveBatch}
        templates={templates}
      />
      <AddTemplateDialog
        open={openTemplateDialog}
        onClose={() => {
          setOpenTemplateDialog(false);
        }}
        insertTemplates={insertTemplates}
      />
      <AddBatchDialog
        open={openBatchDialog}
        onClose={() => {
          setOpenBatchDialog(false);
        }}
        insertTemplates={insertTemplates}
      />
    </Box>
  );
};

export default BatchBuilder;
