import React from 'react';
import { Paper, Typography, Chip, Tooltip, Stack, TextField, Box, Button, IconButton } from '@mui/material';
import { createVariableData } from '../queryTemplateBuilder';
import SettingsIcon from '@mui/icons-material/Settings';
import { VAR_TYPE } from '../../constants';
import AddIcon from '@mui/icons-material/Add';

/**
 * Renders a box with selectable items representing variables or constant strings
 * @param {String} title: The name at the top of the table
 * @param {Array<Object>} variables: All of the variables/constants that will make up the rows
 * @param {Function} insertQueryContent: A function allowing insertion of query terms into the current query
 * @param {String} termType: Type of term, used for edit
 * @param {Function} editCallback: A function to edit the list
 * @returns {React.ReactNode}: The rendered page
 */
const TermList = (props) => {
  const { title, variables, insertQueryContent, termType, editCallback } = props;

  const [customValue, setCustomValue] = React.useState('');

  // generate rows in the variableData structure
  const rows = variables.map((variable, index) => {
    return createVariableData(variable._id, variable.name, variable.term_type, variable.display, variable.description, variable.custom);
  });

  const constantKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleInsertConstant();
    }
  };

  const handleInsertConstant = () => {
    insertQueryContent(customValue.trim(), VAR_TYPE.CONSTANT, customValue.trim(), '', true);
    setCustomValue('');
  };

  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Stack direction="row" alignItems="center" sx={{ mb: 0.5 }}>
        <Typography color="primary" variant="h6" sx={{ p: 0, m: 0, flex: 1 }}>
          {title}
        </Typography>
        <IconButton onClick={() => editCallback(termType)}>
          <SettingsIcon color="primary" />
        </IconButton>
      </Stack>
      {rows.map((row) => (
        <Tooltip title={row.varDescription} enterDelay={500} key={row.varString}>
          <Chip
            onClick={() => {
              insertQueryContent(row.varString, row.varType, row.varDisplay, row.varDescription, row.varCustom);
            }}
            color={termType === VAR_TYPE.VARIABLE ? 'primary' : 'veryDark'}
            label={row.varDisplay}
            sx={{ m: 0.5 }}
          />
        </Tooltip>
      ))}
      {termType === VAR_TYPE.CONSTANT && (
        <Stack sx={{ mt: 2 }}>
          <Box display="flex" justifyContent="right">
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={customValue}
              label="Custom Value"
              onKeyDown={constantKeyPress}
              onChange={(event) => {
                setCustomValue(event.target.value);
              }}
            />
            <Button sx={{ p: 0, minWidth: '32px' }} onClick={handleInsertConstant} disabled={customValue.trim() === ''}>
              <AddIcon />
            </Button>
          </Box>
          <Typography color="red" variant="caption">
            Saved Data - NO PII
          </Typography>
        </Stack>
      )}
    </Paper>
  );
};

export default TermList;
