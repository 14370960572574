import React from 'react';
import { Chip } from '@mui/material';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';

const FilterChip = (props) => {
  const { label, filterMethod, isRemoval } = props;

  const chipProps = isRemoval
    ? {
        onClick: () => {
          filterMethod(false);
        },
        color: 'primary',
        icon: <RadioButtonChecked />,
      }
    : {
        onClick: () => {
          filterMethod(true);
        },
        variant: 'outlined',
        icon: <RadioButtonUnchecked />,
      };

  return <Chip label={label} {...chipProps} sx={{ mr: 1 }} />;
};

export default FilterChip;
