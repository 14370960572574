// Helper tool for theming https://bareynol.github.io/mui-theme-creator/

const BaseTheme = {
  palette: {
    primary: {
      main: '#1f5492',
      light: '#4287d7',
      dark: '#163d69',
      contrastText: '#fff',
    },
    secondary: {
      main: '#cf112a',
    },
    veryDark: {
      main: '#333',
      light: '#666',
      dark: '#000',
      contrastText: '#fff',
    },
    greyed: {
      main: '#aaa',
      light: '#999',
      dark: '#666',
      contrastText: '#fff',
    },
    termVariable: {
      main: '#96bce9',
      light: '#d5e4f6',
      dark: '#1f5492',
      contrastText: '#000',
    },
    termConstant: {
      main: '#909090',
      light: '#f0f0f0',
      dark: '#303030',
      contrastText: '#000',
    },
    termOperation: {
      main: '#a2dda5',
      light: '#daf1db',
      dark: '#308234',
      contrastText: '#000',
    },
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: '#1f549255',
          },
        },
      },
    },
  },
};

export default BaseTheme;
