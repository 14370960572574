import React, { useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import axiosInstance from '../axiosConfig';
import { v4 as uuid } from 'uuid';
import { Button, Typography, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'notistack';
import { VAR_TYPE } from '../constants';
import { saveTemplate } from './components/saveTemplate';
import TermList from './components/termList.js';
import OperationSelection from './components/operationSelection';
import { useStickyState } from '../utils';
import TermListEditorDialog from './components/termListEditorDialog.js';
import TemplateEditor from './components/templateEditor.js';
import SaveIcon from '@mui/icons-material/Save.js';
import ClearAllIcon from '@mui/icons-material/ClearAll.js';
import { formatAPIError } from '../axiosConfig';

/**
 * Renders the template builder page, allowing the user to construct and save query templates
 * @returns Rendered template builder
 */
const QueryTemplateBuilder = (props) => {
  const { onSave } = props;
  const [queryContents, setQueryContents] = useStickyState([], 'queryContents');
  const { enqueueSnackbar } = useSnackbar();
  const [variableList, setVariableList] = useState([]);
  const [constantList, setConstantList] = useState([]);
  const [editVarListOpen, setEditVarListOpen] = useState(false);
  const [editConstListOpen, setEditConstListOpen] = useState(false);

  const disableSaveClearButtons = queryContents.length === 0 ? true : false;

  const saveTemplateMutation = useMutation({
    mutationFn: (newTemplate) => {
      return axiosInstance.post('template/', newTemplate);
    },
    onIsLoading: (data, error, variables, context) => {
      // request still processing
      console.log(`Uploading new template...`);
    },
    onError: (error, variables, context) => {
      enqueueSnackbar('Unable to Save Template: ' + formatAPIError(error), { variant: 'error' });
    },
    onSuccess: (data, variables, context) => {
      console.log(data);
      enqueueSnackbar('Saved New Template: ' + data.data.name, { variant: 'success' });
      clearQuery();
      onSave && onSave();
    },
  });

  /**
   * Insert the currently selected row and operation into the queryContents object
   * @param {String} newVarString: The value of the variable/constant/operation to add
   * @param {String} newVarType: The type (variable/constant/operation) of the term to add
   */
  const insertQueryContent = (newVarString, newVarType, display, description, custom) => {
    setQueryContents((queryContents) => [
      ...queryContents,
      {
        id: uuid(),
        varString: newVarString,
        varType: newVarType,
        varDisplay: display,
        varDescription: description,
        varCustom: custom,
      },
    ]);
  };

  /**
   * Displays the save template dialog, then verifies the user inputs and calls the API to save it
   */
  const saveQuery = () => {
    saveTemplate({ show: true }).then(
      ({ name, desc, privateValue, tags }) => {
        if (name === '') {
          // Requires a name to be provided to save.
          enqueueSnackbar('No template name was provided.', { variant: 'error' });
          return;
        }

        var newTemplate = {
          _ids: {
            tags: {
              replace: [],
            },
          },
          name: name,
          variables: [],
          private: privateValue,
          tags: tags,
        };
        if (desc !== '') {
          // The REST endpoint does not allow for empty string values, only adding description if its entered.
          newTemplate['description'] = desc;
        }
        queryContents.forEach((item) => {
          newTemplate['variables'].push({
            var_type: item.varType,
            value: item.varString.trim(),
            description: item.varDescription,
            display: item.varDisplay,
          });
        });

        console.log(newTemplate);
        saveTemplateMutation.mutate(newTemplate);
      },
      () => {
        console.log('cancelled');
      }
    );
  };

  const clearQuery = () => {
    setQueryContents([]);
  };

  /**
   * Call the API to retrieve a list of variables and update the variables state
   */
  const getTerms = () => {
    axiosInstance
      .get('/template-term/')
      .then((response) => {
        const responseItems = response.data.results;
        setVariableList(responseItems.filter((v) => v['term_type'] === VAR_TYPE.VARIABLE));
        setConstantList(responseItems.filter((v) => v['term_type'] === VAR_TYPE.CONSTANT));
      })
      .catch((error) => console.error('Error: ' + error));
  };

  /**
   * useEffect to trigger on page load and retrieve user custom variables
   */
  useEffect(() => {
    getTerms();
  }, []);

  return (
    <Box>
      <Grid container spacing={2} alignItems="stretch">
        <Grid xs={12}>
          <Typography className="blockquote center" variant="body">
            Click on variables, constants, and operators to add them to the query. Drag and drop to rearrange terms. Drop in trash can to
            delete.
          </Typography>
        </Grid>

        <Grid xs={12}>
          <TemplateEditor queryContents={queryContents} setQueryContents={setQueryContents} />
        </Grid>

        <Grid xs={4}>
          <TermList
            title={'Variables'}
            variables={variableList}
            insertQueryContent={insertQueryContent}
            termType={VAR_TYPE.VARIABLE}
            editCallback={() => {
              setEditVarListOpen(true);
            }}
          />
        </Grid>

        <Grid xs={4}>
          <TermList
            title={'Constants'}
            variables={constantList}
            insertQueryContent={insertQueryContent}
            termType={VAR_TYPE.CONSTANT}
            editCallback={() => {
              setEditConstListOpen(true);
            }}
            sx={{ st: 2 }}
          />
        </Grid>

        <Grid xs={4}>
          <OperationSelection queryContents={queryContents} setQueryContents={setQueryContents} />
        </Grid>

        <Grid xs={6} display="flex" justifyContent="left">
          <Button variant="contained" onClick={clearQuery} disabled={disableSaveClearButtons} color="error" startIcon={<ClearAllIcon />}>
            Clear
          </Button>
        </Grid>

        <Grid xs={6} display="flex" justifyContent="right">
          <Button variant="contained" onClick={saveQuery} disabled={disableSaveClearButtons} startIcon={<SaveIcon />}>
            Save as New Template
          </Button>
        </Grid>
      </Grid>

      <TermListEditorDialog
        open={editVarListOpen}
        onClose={(info) => {
          setEditVarListOpen(false);
          getTerms();
        }}
        termType={VAR_TYPE.VARIABLE}
      />
      <TermListEditorDialog
        open={editConstListOpen}
        onClose={(info) => {
          setEditConstListOpen(false);
          getTerms();
        }}
        termType={VAR_TYPE.CONSTANT}
      />
    </Box>
  );
};

/**
 * Creates a consistent object representing a variable data row
 * @param {number} id: The id given to the row
 * @param {String} [name]: The string representing the variable name or constant value
 * @param {String} [termType]: Either 'variable' or 'constant'
 * @param {String} [display] A nicer formatting of the name
 * @param {String} [description] tooltip, optional
 * @param {bool} [custom] built-in or custom, optional
 * @returns
 */
export const createVariableData = (id, name, termType, display, description = '', custom = false) => {
  return {
    id: id,
    varString: name,
    varType: termType,
    varDisplay: display,
    varDescription: description,
    varCustom: custom,
  };
};

/**
 * A helper to easily compare 2 variableData objects
 * @param {Object} varData1: The first variableData object/row
 * @param {Object} varData2: The second variableData object/row
 * @returns {bool} True if they are the same object/row, false otherwise
 */
export const isVariableDataEqual = (varData1, varData2) => {
  if (varData1.id === varData2.id && varData1.varString === varData2.varString && varData1.varType === varData2.varType) {
    return true;
  } else {
    return false;
  }
};

export default QueryTemplateBuilder;
