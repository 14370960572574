import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { confirmable, createConfirmation } from 'react-confirm';
import { passwordRequirementsText } from '../../components/passwordChangeDialog';
import PasswordField from '../../components/passwordField';

const CreateUserModal = (props) => {
  const { show, proceed, dismiss, cancel } = props;

  const [formState, setFormState] = useState({
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    is_admin: false,
    is_active: true,
    password: '',
  });

  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const handleSave = () => {
    proceed(formState);
  };

  useEffect(() => {
    setIsReady(isPasswordValid && formState.username && formState.email && formState.first_name && formState.last_name);
  }, [isPasswordValid, formState]);

  const setPassword = (p) => {
    setFormState({ ...formState, password: p });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleBoolChange = (event) => {
    const { name, checked } = event.target;
    setFormState((prevState) => ({ ...prevState, [name]: checked }));
  };

  return (
    <div>
      <Dialog open={show} onClose={dismiss}>
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {passwordRequirementsText}
            <br />
            NOTE: User must change password at next logon
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="username"
            label="Username"
            type="text"
            fullWidth
            variant="standard"
            required
            name="username"
            value={formState.username}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            id="email"
            label="Email"
            name="email"
            type="text"
            fullWidth
            variant="standard"
            required
            value={formState.email}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            id="first_name"
            label="First Name"
            type="text"
            fullWidth
            variant="standard"
            required
            value={formState.first_name}
            onChange={handleInputChange}
            name="first_name"
          />
          <TextField
            margin="dense"
            id="last_name"
            label="Last Name"
            type="text"
            fullWidth
            variant="standard"
            required
            value={formState.last_name}
            onChange={handleInputChange}
            name="last_name"
          />
          <PasswordField
            margin="dense"
            id="password"
            label="Password"
            fullWidth
            variant="standard"
            password={formState.password}
            setPassword={setPassword}
            checkReq={true}
            valid={isPasswordValid}
            setValid={setIsPasswordValid}
          />
          <FormControlLabel
            control={<Switch color="primary" />}
            label="Admin"
            labelPlacement="start"
            checked={formState.is_admin}
            name="is_admin"
            onChange={handleBoolChange}
          />
          <FormControlLabel
            control={<Switch color="primary" />}
            label="Enabled"
            labelPlacement="start"
            checked={formState.is_active}
            onChange={handleBoolChange}
            name="is_active"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel}>Cancel</Button>
          <Button disabled={!isReady} onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const createuserModal = createConfirmation(confirmable(CreateUserModal));
