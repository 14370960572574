import React from 'react';
import { Box, CircularProgress } from '@mui/material';

/**
 * Renders a MUI box with a loading animation playing in the center
 */
const CenteredProgress = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  );
};

export default CenteredProgress;
