import React, { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Switch,
  Stack,
} from '@mui/material/';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

/**
 * Renders a dialog allowing the user to name and describe a batch, then attempt to save it with the provided save function
 * @param {bool} open: Is the dialog open or not
 * @param {Function} onClose: A function that should set open to false
 * @param {Object} exportSettings: The state object holding the user's export settings values
 * @param {Function} setExportSettings: A function allowing modification of the exportSettings state
 * @returns
 */
const ExportResultsDialog = (props) => {
  const { open, onClose, handleExport, exportSettings, setExportSettings } = props;

  /**
   * User clicked export, call the supplied export function, and close form
   */
  const handleExportClick = () => {
    handleExport();
    onClose();
  };

  const [onlyFlagged, setOnlyFlagged] = useState(false);

  const handleChangeExportFormat = (newFormat) => {
    setExportSettings({
      ...exportSettings,
      format: newFormat,
    });
  };

  const handleToggleFlaggedOnly = () => {
    setExportSettings({
      ...exportSettings,
      flaggedOnly: !exportSettings.flaggedOnly,
    });
    setOnlyFlagged(!onlyFlagged);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Export Results</DialogTitle>
      <DialogContent>
        <DialogContentText>Download search results.</DialogContentText>
        <br />
        <Grid container>
          <Grid xs={12}>
            <Stack>
              <Typography variant="h6">Options</Typography>
              <Divider />
              <FormControl sx={{ mt: 2, maxWidth: '256px' }}>
                <InputLabel id="export-format-select">Format</InputLabel>
                <Select
                  size="small"
                  labelId="export-format-select"
                  value={exportSettings.format}
                  label="Format"
                  onChange={(e) => handleChangeExportFormat(e.target.value)}
                >
                  <MenuItem value={'xml'}>XML</MenuItem>
                  <MenuItem value={'csv'}>CSV</MenuItem>
                  <MenuItem value={'json'}>JSON</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                control={<Switch color="primary" />}
                label="Only Flagged"
                labelPlacement="end"
                onChange={handleToggleFlaggedOnly}
                checked={onlyFlagged}
              />
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleExportClick} startIcon={<FileDownloadIcon />}>
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportResultsDialog;
